import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";

const Logout = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout, navigate]);

  // simply show pageloader as redirect occurs
  return (
    <div style={{ height: "100vh" }}>
      <LoadingScreen
        header={t('logout.header')}
        text={t('logout.text')}
      />
    </div>
  );
};

export default Logout;