import i18n from "i18next";

export const Colors = {
  RED: i18n.t("enum.colors.red"),
  GREEN: i18n.t("enum.colors.green"),
  BLUE: i18n.t("enum.colors.blue"),
};

export const VolumeUnits = {
  ML: i18n.t("enum.volumeUnits.ml"),
  UL: i18n.t("enum.volumeUnits.ul"),
};

export const Severity = {
  ERROR: i18n.t("enum.severity.error"),
  SUCCESS: i18n.t("enum.severity.success"),
};

export const options = [
  { value: "Petri Dish - Yeast", label: "Petri Dish - Yeast" },
  { value: "Petri Dish - E. Coli", label: "Petri Dish - E. Coli" },
  { value: "Listeria Palcam", label: "Listeria Palcam" },
  { value: "Listeria Oxford", label: "Listeria Oxford" },
  { value: "Salmonella", label: "Salmonella" },
  { value: "Contaminants", label: "Contaminants" },
  { value: "GFP/OFP", label: "GFP/OFP" },
  { value: "Membrane", label: "Membrane Filtration" },
  // { value: "Smear", label: "Blood Smear - RBC" },
  { value: "Smear WBC", label: "Blood Smear - WBC" },
  { value: "Gel Electrophoresis", label: "Gel Electrophoresis" },
];

export const BloodSmearRBCTypes = {
  Acanthocyte: "Acanthocyte",
  Codocyte: "Codocyte",
  Dacrocyte: "Dacrocyte",
  Degmacytes: "Degmacytes",
  Echinocyte: "Echinocyte",
  Ovalocyte: "Ovalocyte",
  Platelet: "Platelet",
  RBC: "RBC",
  Schistocyte: "Schistocyte",
  Spherocyte: "Spherocyte",
  Stomatocyte: "Stomatocyte",
};

export const BloodSmearWBCTypes = {
  BandNeutrophil: "Band-Neutrophil",
  Basophil: "Basophil",
  Blast: "Blast",
  Eosinophil: "Eosinophil",
  Lymphocyte: "Lymphocyte",
  Metamyelocyte: "Metamyelocyte",
  Monocyte: "Monocyte",
  Myelocyte: "Myelocyte",
  Neutrophil: "Neutrophil",
  NeutrophilHypersegmented: "Neutrophil-hypersegmented",
  OrthoNRBC: "Ortho-NRBC",
};

export const Assay7Classes = {
  GFP: "GFP",
  OFP: "OFP",
};

export const GFP_OFP_Classes = {
  0: "GFP",
  1: "OFP",
};

export const DNALadders = {
  INVITROGEN_1KB_PLUS: {
    name: "Invitrogen 1 kb Plus",
    type: "DNA",
    bands: [
      100, 200, 300, 400, 500, 650, 850, 1000, 1650, 2000, 3000, 4000, 5000,
      6000, 7000, 8000, 9000, 10000, 11000, 12000,
    ],
    number_of_bands: 20,
  },
  INVITROGEN_LOW_DNA_MASS: {
    name: "Invitrogen Low DNA Mass",
    type: "DNA",
    bands: [100, 200, 400, 800, 1200, 2000],
    number_of_bands: 6,
  },
  INVITROGEN_50_BP: {
    name: "Invitrogen 50 bp",
    type: "DNA",
    bands: [
      50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750,
      800,
    ],
    number_of_bands: 16,
  },
  INVITROGEN_ULTRA_LOW_RANGE: {
    name: "Invitrogen Ultra Low Range",
    type: "DNA",
    bands: [10, 20, 30, 40, 50, 75, 100, 150, 200, 300],
    number_of_bands: 10,
  },
  INVITROGEN_HIGH_DNA_MASS: {
    name: "Invitrogen High DNA Mass",
    type: "DNA",
    bands: [1000, 2000, 3000, 4000, 6000, 10000],
    number_of_bands: 6,
  },
  INVITROGEN_100_BP: {
    name: "Invitrogen 100 bp",
    type: "DNA",
    bands: [
      100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1200, 1500, 2000,
    ],
    number_of_bands: 13,
  },
  GENERULER_1KB: {
    name: "GeneRuler 1 kb",
    type: "DNA",
    bands: [
      250, 500, 750, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 5000, 6000, 8000,
      10000,
    ],
    number_of_bands: 14,
  },
  GENERULER_DNA: {
    name: "GeneRuler DNA",
    type: "DNA",
    bands: [
      100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1200, 1500, 2000, 3000,
    ],
    number_of_bands: 14,
  },
  GENERULER_1KB_PLUS: {
    name: "GeneRuler 1 kb Plus",
    type: "DNA",
    bands: [
      75, 200, 300, 400, 500, 700, 1000, 1500, 2000, 3000, 4000, 5000, 7000,
      10000, 20000,
    ],
    number_of_bands: 15,
  },
  GENERULER_100_BP: {
    name: "GeneRuler 100 bp",
    type: "DNA",
    bands: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
    number_of_bands: 10,
  },
  GENERULER_100_BP_PLUS: {
    name: "GeneRuler 100 bp Plus",
    type: "DNA",
    bands: [
      100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1200, 1500, 2000, 3000,
    ],
    number_of_bands: 14,
  },
  GENERULER_50BP: {
    name: "GeneRuler 50bp",
    type: "DNA",
    bands: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
    number_of_bands: 10,
  },
  GENERULER_ULTRA_LOW_RANGE: {
    name: "GeneRuler Ultra Low Range",
    type: "DNA",
    bands: [10, 20, 30, 40, 50, 75, 100, 150, 200, 300],
    number_of_bands: 10,
  },
  GENERULER_LOW_RANGE: {
    name: "GeneRuler Low Range",
    type: "DNA",
    bands: [25, 50, 75, 100, 150, 200, 300, 400, 500, 700],
    number_of_bands: 10,
  },
  GENERULER_HIGH_RANGE: {
    name: "GeneRuler High Range",
    type: "DNA",
    bands: [500, 1000, 1500, 2000, 3000, 4000, 5000, 6000, 8000, 10000],
    number_of_bands: 10,
  },
  GENERULER_EXPRESS: {
    name: "GeneRuler Express",
    type: "DNA",
    bands: [100, 200, 500, 1000, 1500, 2000, 3000, 5000],
    number_of_bands: 8,
  },
};

export const RNALadders = {
  INVITROGEN_AMBION_RNA_6000: {
    name: "Invitrogen Ambion RNA 6000",
    type: "RNA",
    bands: [200, 500, 1000, 2000, 4000, 6000],
    number_of_bands: 6,
  },
  INVITROGEN_CENTURY_PLUS: {
    name: "Invitrogen Century Plus",
    type: "RNA",
    bands: [
      100, 200, 300, 400, 500, 750, 1000, 1500, 2000, 2500, 3000, 4000, 5000,
      6000,
    ],
    number_of_bands: 14,
  },
  INVITROGEN_AMBION_MILLENNIUM_MARKERS_FORMAMIDE: {
    name: "Invitrogen Ambion Millennium Markers-Formamide",
    type: "RNA",
    bands: [500, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 6000, 9000],
    number_of_bands: 10,
  },
  INVITROGEN_AMBION_RNA_MILLENNIUM_MARKERS: {
    name: "Invitrogen Ambion RNA Millennium Markers",
    type: "RNA",
    bands: [500, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 6000, 9000],
    number_of_bands: 10,
  },
  THERMO_SCIENTIFIC_RIBORULER_LOW_RANGE: {
    name: "Thermo Scientific RiboRuler Low Range",
    type: "RNA",
    bands: [100, 200, 300, 400, 600, 800, 1000, 1500],
    number_of_bands: 8,
  },
  THERMO_SCIENTIFIC_RIBORULER_HIGH_RANGE: {
    name: "Thermo Scientific RiboRuler High Range",
    type: "RNA",
    bands: [200, 500, 1000, 1500, 2000, 3000, 4000, 6000],
    number_of_bands: 8,
  },
};

export const ProteinLadders = {
  THERMO_SCIENTIFIC_PAGERULER_PLUS_PRESTAINED: {
    name: "Thermo Scientific PageRuler Plus Prestained",
    type: "Protein",
    bands: [10, 15, 25, 35, 55, 70, 100, 130, 250],
    number_of_bands: 9,
  },
  THERMO_SCIENTIFIC_SPECTRA_MULTICOLOR_BROAD_RANGE: {
    name: "Thermo Scientific Spectra Multicolor Broad Range",
    type: "Protein",
    bands: [10, 15, 25, 35, 40, 50, 70, 100, 140, 260],
    number_of_bands: 10,
  },
  INVITROGEN_HIMARK_PRE_STAINED_PROTEIN_STANDARD: {
    name: "Invitrogen HiMark Pre-Stained Protein Standard",
    type: "Protein",
    bands: [30, 40, 50, 60, 80, 100, 150, 190, 220, 260, 460],
    number_of_bands: 11,
  },
  INVITROGEN_SEEBLUE_PRE_STAINED_STANDARD: {
    name: "Invitrogen SeeBlue Pre-Stained Standard",
    type: "Protein",
    bands: [3, 6, 14, 17, 28, 38, 49, 62, 98, 188],
    number_of_bands: 10,
  },
  INVITROGEN_IBRIGHT_PRESTAINED: {
    name: "Invitrogen iBright Prestained",
    type: "Protein",
    bands: [11, 17, 28, 36, 55, 72, 95, 130, 250],
    number_of_bands: 9,
  },
  INVITROGEN_MAGICMARK_XP_WESTERN_PROTEIN_STANDARD: {
    name: "Invitrogen MagicMark XP Western Protein Standard",
    type: "Protein",
    bands: [20, 30, 40, 50, 60, 80, 100, 120, 220],
    number_of_bands: 9,
  },
  THERMO_SCIENTIFIC_PAGERULER_UNSTAINED: {
    name: "Thermo Scientific PageRuler Unstained",
    type: "Protein",
    bands: [10, 15, 20, 25, 30, 40, 50, 60, 70, 85, 100, 120, 150, 200],
    number_of_bands: 14,
  },
  THERMO_SCIENTIFIC_PAGERULER_UNSTAINED_BROAD_RANGE: {
    name: "Thermo Scientific PageRuler Unstained Broad Range",
    type: "Protein",
    bands: [
      5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 120, 150, 200, 250,
    ],
    number_of_bands: 17,
  },
  INVITROGEN_HIMARK_UNSTAINED_HMW_PROTEIN_STANDARD: {
    name: "Invitrogen HiMark Unstained High Molecular Weight (HMW) Protein Standard",
    type: "Protein",
    bands: [
      20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 120, 150, 190, 220, 260, 460,
    ],
    number_of_bands: 16,
  },
  THERMO_SCIENTIFIC_PAGERULER_UNSTAINED_LOW_RANGE: {
    name: "Thermo Scientific PageRuler Unstained Low Range",
    type: "Protein",
    bands: [3, 5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 100, 150, 200],
    number_of_bands: 14,
  },
  INVITROGEN_NATIVEMARK_UNSTAINED_PROTEIN_STANDARD: {
    name: "Invitrogen NativeMark Unstained Protein Standard",
    type: "Protein",
    bands: [20, 66, 146, 242, 480, 720, 1048, 1236],
    number_of_bands: 8,
  },
  INVITROGEN_E_PAGE_SEEBLUE_PRE_STAINED_STANDARDS: {
    name: "Invitrogen E-PAGE SeeBlue Pre-stained Standards",
    type: "Protein",
    bands: [3, 6, 14, 17, 28, 38, 49, 62, 98, 188],
    number_of_bands: 10,
  },
  INVITROGEN_BENCHMARK_HIS_TAGGED_PROTEIN_STANDARD: {
    name: "Invitrogen BenchMark His-tagged Protein Standard",
    type: "Protein",
    bands: [10, 15, 20, 25, 30, 40, 50, 60, 70, 110, 160],
    number_of_bands: 11,
  },
  INVITROGEN_PEPPERMINT_STICK_PHOSPHOPROTEIN_MOLECULAR_WEIGHT_STANDARDS: {
    name: "Invitrogen PeppermintStick Phosphoprotein Molecular Weight Standards",
    type: "Protein",
    bands: [14.4, 18, 23.6, 45, 66, 116],
    number_of_bands: 6,
  },
  INVITROGEN_CANDYCANE_GLYCOPROTEIN_MOLECULAR_WEIGHT_STANDARDS: {
    name: "Invitrogen CandyCane glycoprotein molecular weight standards",
    type: "Protein",
    bands: [14, 180],
    number_of_bands: 2,
  },
};

export const LadderTypes = {
  DNA: "DNA",
  RNA: "RNA",
  PROTEIN: "Protein",
};

export const AllLadders = {
  ...DNALadders,
  ...RNALadders,
  ...ProteinLadders,
};
