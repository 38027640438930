import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const EditUser = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/users/updateuser`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const GetUserByUsername = async (accessToken, username) => {
  const config = {
    url: `${apiServerUrl}/users/getuserbyusername/${username}`,
    method: "GET",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const GetUserByID = async (accessToken, authID, email) => {
  const config = {
    url: `${apiServerUrl}/users/getuserbyid/${authID}/${email}`,
    method: "GET",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const GetAllUsers = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/users/getallusers`,
    method: "GET",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const GetAllUpdatedUsers = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/users/getallupdatedusers`,
    method: "GET",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const GetStats = async (accessToken, id) => {
  const config = {
    url: `${apiServerUrl}/users/getGeneralStats/${id}`,
    method: "GET",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const ResetStats = async (accessToken, id) => {
  const config = {
    url: `${apiServerUrl}/users/resetStats/${id}`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const GetUserHasPremium = async (accessToken, id) => {
  const config = {
    url: `${apiServerUrl}/users/hasPremium`,
    method: "GET",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};