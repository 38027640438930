// React
import React, { useState, useEffect } from 'react';
// Services
import { GetProject } from '../../services/project.service';
// MUI
import { ThemeProvider } from '@mui/system';
import { theme } from '../../theme';
// Components
import PageHeader from './Components/PageHeader/PageHeader';
import ProjectBody from './Components/ProjectBody/ProjectBody';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
// CSS
import './Project.css';
// Animation
import { motion } from "framer-motion";
// i18n
import { useTranslation } from 'react-i18next';

const Project = ({ accessToken, user, setProjectDeleted }) => {
    const { t } = useTranslation();
    const [projectID, setProjectID] = useState(null);
    const [projectTitle, setProjectTitle] = useState(null);
    const [projectColor, setProjectColor] = useState(null);
    const [projectDescription, setProjectDescription] = useState(null);
    const [projectNotes, setProjectNotes] = useState(null);
    const [projectTrials, setProjectTrials] = useState(null);
    const [projectMembers, setProjectMembers] = useState(null);
    const [projectImages, setProjectImages] = useState(null);
    const [projectCreator, setProjectCreator] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setProjectID(window.location.pathname.split('/')[2]);
        if (!accessToken || !projectID) { return; }
        GetProject(accessToken, projectID).then((response) => {
            setLoading(false);
            if (response.error) {
                console.log(response.error);
                return;
            }
            setProjectTitle(response.data.project.project_name);
            setProjectDescription(response.data.project.project_description);
            setProjectNotes(response.data.project.project_notes);
            setProjectTrials(response.data.project_trials);
            setProjectMembers(response.data.project_members);
            setProjectColor(response.data.project.project_color);
            setProjectImages(response.data.images);
            setProjectCreator(response.data.project.project_creator);
        });
    }, [accessToken, projectID]);

    return (
        <ThemeProvider theme={theme}>
            <div className="project-page-wrapper">
                {loading ? (
                    <div style={{ height: "100vh" }}>
                        <LoadingScreen header={t('project.loading.header')} text={t('project.loading.text')} />
                    </div>
                ) : (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <PageHeader
                            projectTitle={projectTitle}
                            projectDescription={projectDescription}
                            projectNotes={projectNotes}
                            projectMembers={projectMembers}
                            accessToken={accessToken}
                            projectID={projectID}
                            user={user}
                            projectColor={projectColor}
                            setProjectDeleted={setProjectDeleted}
                            projectCreator={projectCreator}
                        />
                        <ProjectBody
                            projectTrials={projectTrials}
                            setProjectTrials={setProjectTrials}
                            projectTitle={projectTitle}
                            projectImages={projectImages}
                            projectColor={projectColor}
                            setProjectImages={setProjectImages}
                            accessToken={accessToken}
                            projectID={projectID}
                            userID={user.sub.split("|")[1]}
                        />
                    </motion.div>
                )}
            </div>
        </ThemeProvider>
    );
};

export default Project;
