import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material/styles";
import { Box, TextField, Link, Alert } from "@mui/material";
import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SaveComment } from "../../services/comments.service";
import { ColorButton, theme } from "../../theme";
import About from "./About/About";
import Advisors from "./Advisors/Advisors";
import "./Contact.css";
import Founders from "./Founders/Founders";
import Team from "./Team/Team";

function Contact() {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [messageField, setMessageField] = useState("");
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);

  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      setName(user.name || "");
      setEmail(user.email || "");
    }
  }, [user]);

  //----------Service Functions----------//
  const onclick = async () => {
    if (!name || !email || !subject || !messageField) {
      getMessage(
        null,
        "Please fill in all fields",
        "Comment Submitted!",
        "Please fill in all fields"
      );
      return;
    }
    // regex for email
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      getMessage(
        null,
        "Please enter a valid email",
        "Comment Submitted!",
        "Please enter a valid email"
      );
      return;
    }
    // regex for name
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!nameRegex.test(name)) {
      getMessage(
        null,
        "Please enter a valid name",
        "Comment Submitted!",
        "Please enter a valid name"
      );
      return;
    }
    // regex for subject
    const subjectRegex = /^[a-zA-Z\s]*$/;
    if (!subjectRegex.test(subject)) {
      getMessage(
        null,
        "Please enter a valid subject",
        "Comment Submitted!",
        "Please enter a valid subject"
      );
      return;
    }

    // create form data
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("subject", subject);
    formData.append("message", messageField);

    const { data, error } = await SaveComment(formData);
    getMessage(data, error, "Comment Submitted!", "Error Submitting Comment");
  };

  //----------Helper Functions----------//
  const getMessage = (data, error, successMsg, errorMsg) => {
    // display error or success message
    document.getElementById("messageDiv").hidden = false;
    if (data) {
      // set error to null
      setMessageType("success");
      setMessage(successMsg);
    }
    if (error) {
      // set error to error message for 5 seconds
      setMessageType("error");
      setMessage(errorMsg);
    }
    setTimeout(() => {
      // set message div to hidden after 5 seconds
      document.getElementById("messageDiv").hidden = true;
    }, 3000);
  };

  const openLink = () => {
    window.open("https://meetings.hubspot.com/ali-mehdi1", "_blank");
  };

  return (
    <ThemeProvider theme={theme}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Box className="contact-section">
          <Box className="contact-text-section">
            <p className="contact-section-title">{t("contact.title")}</p>
            <p className="contact-subtitle">{t("contact.subtitle")}</p>
            <ColorButton
              variant="contained"
              color="primary"
              className="book-demo-button"
              onClick={openLink}
            >
              {t("contact.bookDemo")}
            </ColorButton>
            <div className="book-demo-subtitle">
              {t("contact.emailIntro")}
              <span>
                {" "}
                <Link
                  href="mailto:inquiries@pytri.ca"
                  sx={{
                    color: "primary.main",
                    "&:hover": { color: "primary.dark" },
                  }}
                >
                  {" inquiries@pytri.ca "}
                </Link>{" "}
              </span>
              {t("contact.salesEmailText")}
              <span>
                <Link
                  href="mailto:info@pytri.ca"
                  sx={{
                    color: "primary.main",
                    "&:hover": { color: "primary.dark" },
                  }}
                >
                  {" support@pytri.ca "}
                </Link>
              </span>
              {t("contact.supportEmailText")}
            </div>
          </Box>
          <div className="contact-form-container">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="input-field-container">
                <TextField
                  fullWidth
                  label={t("contact.formName")}
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="input-field-container">
                <TextField
                  fullWidth
                  label={t("contact.formEmail")}
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-field-container">
                <TextField
                  fullWidth
                  label={t("contact.formSubject")}
                  value={subject}
                  required
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="input-field-container">
                <TextField
                  fullWidth
                  label={t("contact.formMessage")}
                  value={messageField}
                  required
                  onChange={(e) => setMessageField(e.target.value)}
                />
              </div>
              <div className="submit-btn-container">
                <ColorButton
                  pill
                  size="large"
                  variant="secondary"
                  onClick={onclick}
                  className="submit-button"
                >
                  {t("contact.formSubmit")}
                </ColorButton>
              </div>
            </form>
          </div>
          {message && <Alert severity={messageType}>{message}</Alert>}
        </Box>
        <About />

        <Advisors />
        <Founders />
        <Team />
      </motion.div>
    </ThemeProvider>
  );
}

export default Contact;
