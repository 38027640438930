import NavBar from "./components/TopNavBar/NavBar";
import Footer from "./components/Footer/footer";
import { Box, ThemeProvider } from "@mui/system";
import { theme } from "./theme";

const Layout = ({ userData, InfoUpdated, children, projects, setProjects }) => {
    const appBoxStyles = {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        maxWidth: "100vw",
        backgroundColor: theme.palette.background.default,
        fontFamily: theme.typography.fontFamily,
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={appBoxStyles}>
                <NavBar
                    userData={userData}
                    InfoUpdated={InfoUpdated}
                    projects={projects}
                    setProjects={setProjects}
                />
                <Box
                    sx={{
                        flexGrow: 1,
                        minHeight: "100vh",
                        width: "100%",
                        marginTop: { xs: "64px", sm: "100px", md: "64px" },
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    {children}
                </Box>
                <Footer />
            </Box>
        </ThemeProvider>
    );
};

export default Layout;