import { ThemeProvider, Container, Typography, Box } from "@mui/material";
import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import FAQ from "../../pages/Home/Components/faq/faq";
import { theme } from "../../theme";
import "./Products.css";

// Import your images statically to ensure they are bundled correctly
import egelIcon from "../../assets/images/Products/egel-icon.png";
import egelProd from "../../assets/images/Products/egel-prod.png";
import microscopyIcon from "../../assets/images/Products/microscopy-icon.png";
import microscopyProd from "../../assets/images/Products/microscopy-prod.png";
import petriIcon from "../../assets/images/Products/petri-icon.png";
import petriProd from "../../assets/images/Products/petri-prod.png";

function Products() {
    const { t } = useTranslation();
    const scrollRef = useRef();

    const productsData = [
        {
            id: "petri-dish-analysis",
            icon: petriIcon,
            image: petriProd,
            titleKey: "products.microbiology.title",
            textKey: "products.microbiology.text",
            includedKey: "products.microbiology.included",
        },
        {
            id: "microscopy",
            icon: microscopyIcon,
            image: microscopyProd,
            titleKey: "products.hematology.title",
            textKey: "products.hematology.text",
            includedKey: "products.hematology.included",
        },
        {
            id: "egel",
            icon: egelIcon,
            image: egelProd,
            titleKey: "products.drugDevelopment.title",
            textKey: "products.drugDevelopment.text",
            includedKey: "products.drugDevelopment.included",
        },
    ];

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        const offset = -100;
        const offsetTop = section.offsetTop + offset;
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
    };

    const ProductSection = ({ product, index }) => {
        const ref = useRef(null);
        const isInView = useInView(ref, { once: true, amount: 0.3 });

        return (
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 50 }}
                animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                transition={{ duration: 0.5, delay: 0.2 }}
            >
                <Box
                    className={`products-section ${index % 2 === 0 ? 'image-first' : 'text-first'}`}
                    id={product.id}
                    sx={{ py: 6 }}
                >
                    <Box className="product-content-wrapper">
                        <Box className="product-img">
                            <img
                                src={product.image}
                                alt={t(product.titleKey)}
                            />
                        </Box>
                        <Box className="section-content">
                            <Typography variant="h3" component="h2" gutterBottom>
                                {t(product.titleKey)}
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 2 }}>
                                {t(product.textKey)}
                            </Typography>
                            <Box component="ul" sx={{ pl: 3, m: 0 }}>
                                {t(product.includedKey, { returnObjects: true }).map((item, index) => (
                                    <Typography component="li" variant="body2" key={index} sx={{ mb: 1 }}>
                                        {item}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </motion.div>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Container maxWidth="lg" className="products-container">
                    <Box className="section-content-center" sx={{ py: 6 }}>
                        <Typography variant="h2" component="h1" gutterBottom sx={{ mb: 2 }}>
                            {t("products.mainTitle")}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 4, maxWidth: "800px", margin: "0 auto" }}>
                            {t("products.mainDescription")}
                        </Typography>
                        <Box className="product-options" sx={{ mt: 4, flexWrap: "wrap", display: "flex", justifyContent: "center" }}>
                            {productsData.map((product) => (
                                <Box
                                    className="product-option"
                                    key={product.id}
                                    sx={{ cursor: 'pointer', textAlign: 'center', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                    onClick={() => scrollToSection(product.id)}
                                >
                                    <img
                                        src={product.icon}
                                        alt={t(product.titleKey)}
                                        className="product-icon"
                                    />
                                    <Typography variant="h3" className="product-icon-title" sx={{ fontSize: "1.2rem" }}>
                                        {t(product.titleKey)}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>

                    {productsData.map((product, index) => (
                        <ProductSection key={product.id} product={product} index={index} />
                    ))}
                </Container>
            </motion.div>
        </ThemeProvider>
    );
}

export default Products;