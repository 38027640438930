import React from 'react';
import Logo from '../../assets/images/Logos/Pytri_Logo_Black.png';
import "./LoadingScreen.css";

function LoadingScreen({ header, text }) {
    return (
        <div className="loading-screen">
            <img src={Logo} alt="Logo" style={{ width: "100px", height: "auto", marginBottom: "20px", fontSize: 'large' }} className="loading-logo" />
            <h2>{header}</h2>
            <h3 style={{ fontSize: 'medium' }}>{text}</h3>
        </div>
    );
}

export default LoadingScreen;