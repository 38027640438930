import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Founders() {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const founders = [
    { name: "Ali Mehdi", role: "ceo", image: "Ali.jpg" },
    { name: "Abhinav Batra", role: "cto", image: "Abhinav.jpg" },
    { name: "Felix Lambert", role: "R&DAdvisor", image: "felix.jpg" },
    { name: "Valentina Goanta", role: "businessAdvisor", image: "Valentina.jpg" },
    { name: "Michelle Goanta", role: "businessAdvisor", image: "michelle.jpg" },
  ];

  return (
    <Box sx={{ maxWidth: 'md', mx: 'auto', my: 4 }}>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="founders-content"
          id="founders-header"
        >
          <Typography variant="h5">{t("founders.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {founders.map((founder, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ 
                    pt: '100%', 
                    position: 'relative', 
                    overflow: 'hidden' 
                  }}>
                    <Box
                      component="img"
                      src={require(`../../../assets/images/About/${founder.image}`)}
                      alt={founder.name}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography gutterBottom variant="h6" component="div" align="center">
                      {founder.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                      {t(`founders.role.${founder.role}`)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default Founders;