import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Container, Link, useMediaQuery } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { theme } from "../../../../theme";
import { keyframes } from "@mui/system";

const SponsorsMarquee = () => {
  const { t } = useTranslation();
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const marqueeAnimation = keyframes`
    0% { transform: translateX(0%); }
    100% { transform: translateX(-50%); }
  `;

  const sponsors = [
    { name: "medteq", src: require("../../../../assets/images/Sponsors/medteq.png"), url: "https://medteq.ca/" },
    { name: "uoft", src: require("../../../../assets/images/Sponsors/uoft.png"), url: "https://www.utoronto.ca/" },
    { name: "D3", src: require("../../../../assets/images/Sponsors/D3.png"), url: "https://district3.co/" },
    { name: "entreprendre-ici", src: require("../../../../assets/images/Sponsors/entreprendre-ici.png"), url: "https://www.entreprendreici.org/" },
    { name: "futurepreneur", src: require("../../../../assets/images/Sponsors/futurepreneur.png"), url: "https://www.futurpreneur.ca/" },
    { name: "gov", src: require("../../../../assets/images/Sponsors/gov.png"), url: "https://www.economie.gouv.qc.ca/" },
    { name: "lojiq", src: require("../../../../assets/images/Sponsors/lojiq.png"), url: "https://www.lojiq.org/" },
    { name: "pme", src: require("../../../../assets/images/Sponsors/pme.png"), url: "https://pmemtl.com/" },
    { name: "bioquebec", src: require("../../../../assets/images/Sponsors/bioquebec.png"), url: "https://www.bioquebec.com/" },
    { name: "mitacs", src: require("../../../../assets/images/Sponsors/mitacs.png"), url: "https://www.mitacs.ca/" },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ 
          ...theme.customBackgrounds.secondaryGradient, 
          overflow: "hidden",
          py: { xs: 3, sm: 4, md: 5 }, // Slightly reduced vertical padding
        }}
      >
        <Container maxWidth="lg">
          <Typography 
            variant="h2" 
            component="h1" 
            align="center" 
            gutterBottom
            sx={{ 
              fontWeight: "bold", 
              color: "black", 
              mb: { xs: 3, sm: 4, md: 5 }, // Slightly reduced margin-bottom
              fontSize: { xs: '1.5rem', sm: '2.25rem', md: '2.75rem' },
              textTransform: 'uppercase',
            }}
          >
            {t("sponsorsMarquee.title")}
          </Typography>
        </Container>
        <Box
          sx={{
            display: 'flex',
            width: 'calc(200%)', // Ensures the marquee loops seamlessly
            animation: `${marqueeAnimation} ${isMobile ? '25s' : '35s'} linear infinite`, // Slightly faster animation
            '&:hover': { animationPlayState: 'paused' },
            flexWrap: 'nowrap', // Ensures items are in a single line
          }}
        >
          {[...sponsors, ...sponsors].map((sponsor, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: '0 0 auto', // Prevents the box from shrinking or growing
                width: 'auto', // Allows the box to wrap the content tightly
                px: 0, // Removed Horizontal Padding
                py: 0, // Removed Vertical Padding
                filter: 'grayscale(100%)',
                opacity: 0.7,
                transition: 'filter 0.3s ease, opacity 0.3s ease',
                '&:hover': { 
                  filter: 'grayscale(0%)', 
                  opacity: 1,
                  '@media (hover: none)': {
                    filter: 'grayscale(100%)',
                    opacity: 0.7,
                  },
                },
              }}
            >
              <Link 
                href={sponsor.url} 
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  width: '100%', 
                  height: '100%',
                  '&:focus': {
                    outline: '2px solid #000',
                    outlineOffset: '2px',
                  },
                }}
              >
                <Box
                  component="img"
                  src={sponsor.src}
                  alt={sponsor.name}
                  loading="lazy" // Ensures images load only when in viewport
                  sx={{
                    display: 'block', // Removes bottom whitespace
                    maxWidth: '100%',
                    maxHeight: { xs: '50px', sm: '60px', md: '80px' },
                    objectFit: 'contain',
                    width: 'auto',
                    height: '100%', // Ensures the image fills the container's height
                    margin: 5, // Removes any default margins
                  }}
                />
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default SponsorsMarquee;
