import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Paper,
    Modal,
    Typography,
    Box,
    Button,
    ThemeProvider,
    styled
} from '@mui/material';
import TrialTable from './TrialTable/TrialTable';
import { EditProjectTrials } from '../../../../services/project.service';
import CreateTrialModal from '../../../../components/CreateTrial/CreateTrialFlow';
import { createCSV } from '../../../../utils/utils';
import { BloodSmearRBCTypes, BloodSmearWBCTypes } from '../../../../utils/enums';
import { modalStyle, theme } from '../../../../theme';
import ImageEditor from '../../../../components/ImageEditor/ImageEditor';
import { useAuth0 } from '@auth0/auth0-react';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

const ProjectBody = ({
    projectTrials,
    setProjectTrials,
    projectID,
    projectImages,
    setProjectImages,
    userID
}) => {
    const { t } = useTranslation();

    const [editMode, setEditMode] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    const [projectImagesClone, setProjectImagesClone] = useState(null)
    const [projectTrialsClone, setProjectTrialsClone] = useState(null);
    const [trialBeingEdited, setTrialBeingEdited] = useState(null);
    const [creatingTrial, setCreatingTrial] = useState(false);
    const [startDates, setStartDates] = useState([]);
    const [endDates, setEndDates] = useState([]);
    const [imageToOpen, setImageToOpen] = useState(null);
    const [openImageEditor, setOpenImageEditor] = useState(false);
    // e gel data
    const [bandMeasurements, setBandMeasurements] = useState([]);
    const [class0MeasurementsInClass4, setClass0MeasurementsInClass4] = useState([]);
    const [selectedTemplateLadder, setSelectedTemplateLadder] = useState(null);
    const [selectedWells, setSelectedWells] = useState([0]);
    const [BPValueToFind, setBPValueToFind] = useState(null);
    const [BPMarginOfError, setBPMarginOfError] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    // auth0
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const token = await getAccessTokenSilently();
                setAccessToken(token);
            } catch (e) {
                console.error(e);
            }
        };
        getAccessToken();
    }, [getAccessTokenSilently]);

    const resetEGelValues = () => {
        setBandMeasurements([]);
        setClass0MeasurementsInClass4([]);
        setSelectedTemplateLadder(null);
        setSelectedWells([0]);
        setBPValueToFind(null);
        setBPMarginOfError(null);
    };

    useEffect(() => {
        return () => {
            resetEGelValues();
        };
    }, []);

    const cancelEdit = () => {
        setProjectTrials(projectTrialsClone);
        setProjectImages(projectImagesClone);
        setProjectImagesClone(null);
        setEditMode(false);
        setTrialBeingEdited(null);
    };

    const OpenImage = (image) => {
        setOpenImageEditor(true);
        setImageToOpen(image);
    };

    const validateTrialChanges = (trial, images) => {
        if (trial.name === '' || trial.name === null) {
            setSeverity('error');
            setMessage(t('projectBody.errors.trialNameEmpty'));
            setTimeout(() => {
                setSeverity('');
                setMessage('');
            }, 3000);
            return true;
        }
        if (trial.start_date === '') {
            setSeverity('error');
            setMessage(t('projectBody.errors.startDateEmpty'));
            setTimeout(() => {
                setSeverity('');
                setMessage('');
            }, 3000);
            return true;
        }
        if (trial.end_date < trial.start_date && trial.end_date !== '' && trial.end_date !== null) {
            setSeverity('error');
            setMessage(t('projectBody.errors.endDateBeforeStartDate'));
            setTimeout(() => {
                setSeverity('');
                setMessage('');
            }, 3000);
            return true;
        }
        return false;
    };

    const SaveChangesToTrial = () => {
        let trial = projectTrials.find(trial => trial._id === trialBeingEdited);
        let trialIndex = projectTrials.findIndex(trial => trial._id === trialBeingEdited);
        trial.start_date = startDates[trialIndex] ? startDates[trialIndex] : trial.start_date;
        trial.end_date = endDates[trialIndex] ? endDates[trialIndex] : '';
        let images = projectImages.map((trialImages) => trialImages.map((image) => image.trial_id === trial._id ? image : null)).flat().filter((image) => image !== null);

        let errors = validateTrialChanges(trial, images);
        if (errors) {
            return;
        }
        let formdata = new FormData();
        formdata.append('trial', JSON.stringify(trial));
        formdata.append('trial_id', trial._id);
        formdata.append('images', JSON.stringify(images));

        EditProjectTrials(accessToken, formdata).then((response) => {
            if (response.error) {
                setSeverity('error');
                setMessage(t('projectBody.errors.saveChangesError'));
                setTimeout(() => {
                    setSeverity('');
                    setMessage('');
                }, 3000);
            } else {
                setSeverity('success');
                setMessage(t('projectBody.success.changesSaved'));
                setTimeout(() => {
                    setSeverity('');
                    setMessage('');
                }, 3000);
            }
        });

        setTrialBeingEdited(null);
        setEditMode(false);
    };

    const SetEditing = (trialID) => {
        setEditMode(true);
        setProjectImagesClone(JSON.parse(JSON.stringify(projectImages)));
        setProjectTrialsClone(JSON.parse(JSON.stringify(projectTrials)));
        setTrialBeingEdited(trialID);
    };

    const getCellTypes = (assay) => {
        let cellTypes = [];
        if (assay === "Smear") {
            Object.keys(BloodSmearRBCTypes).forEach((key) => {
                cellTypes.push(BloodSmearRBCTypes[key]);
            });
        } else if (assay === "Smear WBC") {
            Object.keys(BloodSmearWBCTypes).forEach((key) => {
                cellTypes.push(BloodSmearWBCTypes[key]);
            });
        } else if (assay === "GFP/OFP") {
            cellTypes.push("GFP");
            cellTypes.push("OFP");
        } else if (assay === "Membrane") {
            cellTypes.push("E. Coli");
            cellTypes.push("Other Coliform");
        } else {
            cellTypes.push("Colony");
        }
        return cellTypes;
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 2,
                    width: '100%'
                }}>
                    <Typography variant="h5" component="h2">
                        {t('projectBody.trialsTitle')}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <ColorButton onClick={() => { setCreatingTrial(true) }}>
                            {t('projectBody.addTrial')}
                        </ColorButton>
                        <ColorButton onClick={() => createCSV(null, projectTrials, projectImages)}>
                            {t('projectBody.exportTrials')}
                        </ColorButton>
                    </Box>
                </Box>

                {projectTrials && projectTrials.length === 0 ? (
                    <Paper sx={{ backgroundColor: 'white', padding: 2, marginTop: 2 }}>
                        <Typography>{t('projectBody.noTrials')}</Typography>
                    </Paper>
                ) : (
                    <TrialTable
                        projectTrials={projectTrials}
                        setProjectTrials={setProjectTrials}
                        projectImages={projectImages}
                        setProjectImages={setProjectImages}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        trialBeingEdited={trialBeingEdited}
                        SetEditing={SetEditing}
                        SaveChangesToTrial={SaveChangesToTrial}
                        cancelEdit={cancelEdit}
                        createCSV={createCSV}
                        OpenImage={OpenImage}
                        accessToken={accessToken}
                    />
                )}


                <Modal
                    open={creatingTrial}
                    onClose={() => { setCreatingTrial(false) }}
                >
                    <Box sx={modalStyle}>
                        <CreateTrialModal
                            selectedProjectID={projectID}
                            accessToken={accessToken}
                            userID={userID}
                            setOpenCreateTrialFlow={setCreatingTrial}
                            openCreateTrialFlow={creatingTrial}
                            projectTrials={projectTrials}
                            setProjectTrials={setProjectTrials}
                            setProjectImages={setProjectImages}
                        />
                    </Box>
                </Modal>


                {imageToOpen && (
                    <Modal
                        open={openImageEditor}
                        onClose={() => { setOpenImageEditor(false); resetEGelValues(); }}
                    >
                        <Box sx={{ ...modalStyle, width: "80%", maxWidth: "99%" }}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 2
                            }}>
                                <Typography variant="h6">
                                    {t('projectBody.editImageTitle')}
                                </Typography>
                                <Button onClick={() => { setOpenImageEditor(false); }}>
                                    {t('projectBody.close')}
                                </Button>
                            </Box>
                            <Box sx={{ p: 2 }}>
                                <ImageEditor
                                    imageData={imageToOpen}
                                    accessToken={accessToken}
                                    setOpenImageEditor={setOpenImageEditor}
                                    multiclassModel={(imageToOpen.assay_type === "Smear" || imageToOpen.assay_type === "Smear WBC" || imageToOpen.assay_type === "GFP/OFP" || imageToOpen.assay_type === "Membrane")}
                                    cellTypes={getCellTypes(imageToOpen.assay_type)}
                                    showHelperMessage={true}
                                    horizontalControls={false}
                                    saveButton={true}
                                    bandMeasurements={bandMeasurements}
                                    setBandMeasurements={setBandMeasurements}
                                    setClass0MeasurementsInClass4={setClass0MeasurementsInClass4}
                                    class0MeasurementsInClass4={class0MeasurementsInClass4}
                                    selectedTemplateLadder={selectedTemplateLadder}
                                    setSelectedTemplateLadder={setSelectedTemplateLadder}
                                    setSelectedWells={setSelectedWells}
                                    selectedWells={selectedWells}
                                    BPValueToFind={BPValueToFind}
                                    setBPValueToFind={setBPValueToFind}
                                    BPMarginOfError={BPMarginOfError}
                                    setBPMarginOfError={setBPMarginOfError}
                                />
                            </Box>
                        </Box>
                    </Modal>
                )}
            </Box>
        </ThemeProvider>
    );
}

export default ProjectBody;