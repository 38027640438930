import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Team() {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const teamMembers = [
    { name: "Nidhi Jampana", role: "uiuxDesigner" },
    { name: "Mika Kaeja", role: "researchAssociate" },
    { name: "Sarah Varughese", role: "researchAssociate" },
    { name: "Ashenafee Mandefro", role: "fullStackDeveloper" },
    { name: "Hamza Abu-Zaid", role: "researchAssociate" },
  ];

  return (
    <Box sx={{ maxWidth: 'md', mx: 'auto', my: 4 }}>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="team-content"
          id="team-header"
        >
          <Typography variant="h5">{t("team.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {teamMembers.map((member, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      pt: '100%',
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      component="img"
                      src={require(`../../../assets/images/About/${member.name.split(' ')[0].toLowerCase()}.jpg`)}
                      alt={member.name}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography gutterBottom variant="h6" component="div" align="center">
                      {member.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                      {t(`team.role.${member.role}`)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default Team;
