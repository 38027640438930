import React from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListSubheader,
    Typography,
    Box
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DNALadders, RNALadders, ProteinLadders, LadderTypes } from '../../utils/enums';

const TemplateLadderDropdown = ({ selectedTemplateLadder, setSelectedTemplateLadder, setBandMeasurements }) => {

    const { t } = useTranslation();

    const handleChange = (event) => {
        const ladder = DNALadders[event.target.value] || RNALadders[event.target.value] || ProteinLadders[event.target.value];
        setSelectedTemplateLadder(ladder);
        if (ladder && setBandMeasurements) {
            setBandMeasurements(ladder.bands);
        }
    };

    const renderLadderMenuItem = (ladder, key) => (
        <MenuItem key={key} value={key}>
            <Box>
                <Typography variant="body2">{ladder.name}</Typography>
                <Typography variant="caption" color="textSecondary">
                    {ladder.number_of_bands} bands, {ladder.bands[0]}-{ladder.bands[ladder.bands.length - 1]} bp
                </Typography>
            </Box>
        </MenuItem>
    );

    const getSelectedLadderKey = () => {
        return Object.keys(DNALadders).find(key => DNALadders[key] === selectedTemplateLadder) ||
               Object.keys(RNALadders).find(key => RNALadders[key] === selectedTemplateLadder) ||
               Object.keys(ProteinLadders).find(key => ProteinLadders[key] === selectedTemplateLadder) ||
               '';
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="ladder-select-label">{t('selectImages.templateLadder.label')}</InputLabel>
            <Select
                labelId="ladder-select-label"
                id="ladder-select"
                value={getSelectedLadderKey()}
                onChange={handleChange}
                renderValue={(selected) => {
                    const ladder = DNALadders[selected] || RNALadders[selected] || ProteinLadders[selected];
                    return ladder ? ladder.name : '';
                }}
            >
                <ListSubheader>{LadderTypes.DNA} Ladders</ListSubheader>
                {Object.entries(DNALadders).map(([key, ladder]) => renderLadderMenuItem(ladder, key))}

                <ListSubheader>{LadderTypes.RNA} Ladders</ListSubheader>
                {Object.entries(RNALadders).map(([key, ladder]) => renderLadderMenuItem(ladder, key))}

                <ListSubheader>{LadderTypes.PROTEIN} Ladders</ListSubheader>
                {Object.entries(ProteinLadders).map(([key, ladder]) => renderLadderMenuItem(ladder, key))}
            </Select>
        </FormControl>
    );
};

export default TemplateLadderDropdown;