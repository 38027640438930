import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { EditUser, GetUserByID } from "../../services/user.service";
import { theme } from "../../theme";
import { ThemeProvider } from "@mui/material/styles";

function Profile({ user, accessToken }) {
  const { t } = useTranslation();
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState("error");
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const sections = [
    { id: "personal-info", label: t("profile.personalInformation") },
    { id: "additional-details", label: t("profile.additionalDetails") },
  ];

  useEffect(() => {
    const fetchUser = async () => {
      const auth0ID = user.sub.split("|")[1];
      const { data } = await GetUserByID(accessToken, auth0ID, user.email);
      if (data) {
        setUserData(data);
      }
    };

    fetchUser();
  }, [accessToken, user.sub, user.email]);

  const validateProfileForm = () => {
    let newErrors = {};

    if (!userData.name || userData.name.trim() === "") {
      newErrors.name = t("profile.errors.nameRequired");
    }

    if (
      !userData.email ||
      !userData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    ) {
      newErrors.email = t("profile.errors.invalidEmail");
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateProfileForm();
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      setMessage(t("profile.errors.fillAllFields"));
      setSeverity("error");
      setTimeout(() => {
        setSeverity("");
        setMessage("");
      }, 3000);
      return;
    } else {
      try {
        await EditUser(accessToken, userData);
        setMessage(t("profile.success.profileUpdated"));
        setSeverity("success");
        setTimeout(() => {
          setSeverity("");
          setMessage("");
        }, 3000);
      } catch (error) {
        setMessage(error.message);
        setSeverity("error");
        setTimeout(() => {
          setSeverity("");
          setMessage("");
        }, 3000);
      }
    }
  };

  const handleNavigationClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          mt: { xs: 8, md: 10 },
          px: { xs: 2, md: 4 },
        }}
      >
        <Grid container spacing={4}>
          {/* Side Navigation */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                position: "sticky",
                top: { xs: 100, md: 120 },
              }}
            >
              <List component="nav">
                {sections.map((section) => (
                  <ListItemButton
                    key={section.id}
                    onClick={() => handleNavigationClick(section.id)}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="body1Bold">
                          {section.label}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                ))}
              </List>
            </Box>
          </Grid>
          {/* Main Content */}
          <Grid item xs={12} md={7} sx={{ mb: 4 }}>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography variant="h1Bold" sx={{ flexGrow: 1, mr: 2 }}>
                  {t("profile.welcome", {
                    name: userData.name || t("profile.user"),
                  })}
                </Typography>
                <Avatar
                  src={user.picture}
                  alt={t("profile.profilePicture")}
                  sx={{ width: 128, height: 128 }}
                />
              </Box>
              <form onSubmit={handleSubmit}>
                {/* Personal Information Section */}
                <Box id="personal-info">
                  <Typography variant="h2Bold">
                    {t("profile.personalInformation")}
                  </Typography>
                  <Grid container spacing={3} sx={{ mt: 3 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("profile.nameLabel")}
                        value={userData.name || ""}
                        required
                        fullWidth
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        onChange={(e) =>
                          setUserData({ ...userData, name: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={t("profile.usernameLabel")}
                        value={user.nickname || ""}
                        required
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={t("profile.emailLabel")}
                        value={userData.email || ""}
                        required
                        fullWidth
                        disabled
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {/* Additional Details Section */}
                <Box id="additional-details" sx={{ mt: 5 }}>
                  <Typography variant="h2Bold">
                    {t("profile.additionalDetails")}
                  </Typography>
                  <Grid container spacing={3} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                      <TextField
                        label={t("profile.institutionLabel")}
                        value={userData.institution || ""}
                        required
                        fullWidth
                        error={Boolean(errors.institution)}
                        helperText={errors.institution}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            institution: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={t("profile.roleLabel")}
                        value={userData.role || ""}
                        fullWidth
                        onChange={(e) =>
                          setUserData({ ...userData, role: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={t("profile.departmentLabel")}
                        value={userData.department || ""}
                        fullWidth
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            department: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={t("profile.areasOfResearchLabel")}
                        value={userData.areas_of_research || ""}
                        fullWidth
                        error={Boolean(errors.areas_of_research)}
                        helperText={errors.areas_of_research}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            areas_of_research: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
                {/* Message and Save Button */}
                {message && (
                  <Alert severity={severity} sx={{ my: 3 }}>
                    {message}
                  </Alert>
                )}
                <Button variant="colorButton" type="submit" sx={{ mt: 2 }}>
                  {t("profile.saveButtonLabel")}
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default Profile;
