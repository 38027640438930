import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { SendVerificationEmail } from "../../services/management.service";
import { useTranslation } from "react-i18next";
import {
  Button,
  Typography,
  Container,
  Box,
  Paper,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const VerifyEmail = () => {
  const [emailSent, setEmailSent] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const resendEmail = async () => {
    if (user) {
      const token = await getAccessTokenSilently();
      try {
        const { data } = await SendVerificationEmail(token, user.sub);
        if (data) {
          setEmailSent(true);
          setOpenSnackbar(true);
        }
      } catch (e) {
        setEmailSent(false);
        console.error(e);
        setOpenSnackbar(true);
      }
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box sx={{ marginTop: 16 }}>
        <StyledPaper elevation={3}>
          <Typography component="h1" variant="h4" gutterBottom>
            {t("verifyEmail.welcome")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("verifyEmail.pleaseVerify")}
          </Typography>

          {!emailSent ? (
            <Box mt={2}>
              <Typography variant="body1" gutterBottom>
                {t("verifyEmail.didNotReceive")}
              </Typography>
              <Button variant="contained" color="primary" onClick={resendEmail}>
                {t("verifyEmail.resend")}
              </Button>
            </Box>
          ) : (
            <Box mt={2}>
              <Typography variant="body1" color="success.main" gutterBottom>
                {t("verifyEmail.emailSent")}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t("verifyEmail.stillHavingTrouble")}
              </Typography>
            </Box>
          )}
        </StyledPaper>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={
          emailSent ? t("verifyEmail.emailSent") : t("verifyEmail.emailError")
        }
      />
    </Container>
  );
};

export default VerifyEmail;
