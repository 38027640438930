import React, { useState, useCallback, useEffect } from 'react';
import {
    IconButton,
    Paper,
    Box,
    Grid,
} from "@mui/material";
import ZoomInRoundedIcon from "@mui/icons-material/ZoomInRounded";
import ZoomOutRoundedIcon from "@mui/icons-material/ZoomOutRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
import HelpIcon from "@mui/icons-material/Help";
import DeleteIcon from "@mui/icons-material/Delete";
import PanToolIcon from '@mui/icons-material/PanTool';
import "./ToolBar.css";

const GelElectrophoresisToolbar = ({
    toolbarRef,
    zoomIn,
    zoomOut,
    resetChanges,
    openTutorial,
    handleDeleteBox,
    box,
    handleDraggingImage,
    isDraggingImage
}) => {

    return (
        <Paper sx={{ width: '100%' }} ref={toolbarRef}>
            <Grid container justifyContent="space-between" alignItems="center">
                <IconButton onClick={zoomIn}>
                    <ZoomInRoundedIcon />
                </IconButton>
                <IconButton onClick={zoomOut}>
                    <ZoomOutRoundedIcon />
                </IconButton>
                <IconButton onClick={resetChanges}><RefreshIcon /></IconButton>
                <IconButton onClick={openTutorial}><HelpIcon /></IconButton>
                <IconButton  onClick={handleDeleteBox} disabled={box === null}>
                    <DeleteIcon/>
                </IconButton>
                 <IconButton onClick={handleDraggingImage} disabled={box !== null} style={{color: isDraggingImage ? 'orange' : 'gray'}}>
                    <PanToolIcon/>
                </IconButton> 
            </Grid>
        </Paper>
    );
};

export default GelElectrophoresisToolbar;