import React from 'react';
import { Modal, Box, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const CloseConfirmationModal = ({
  openConfirmationModal,
  setOpenConfirmationModal,
  setOpenImageUploadModal,
}) => {
  const { t } = useTranslation();

  const styleCloseConfirmation = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    maxWidth: '400px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    borderRadius: '10px',
    p: 3, // Added padding for inner spacing
  };

  return (
    <Modal
      open={openConfirmationModal}
      onClose={() => setOpenConfirmationModal(false)}
    >
      <Box sx={styleCloseConfirmation}>
        {/* Modal Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3Bold">
            {t('selectImages.closeConfirmationModal.title')}
          </Typography>
          <IconButton onClick={() => setOpenConfirmationModal(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Modal Body */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" align="center">
            {t('selectImages.closeConfirmationModal.message')}
          </Typography>
        </Box>

        {/* Modal Actions */}
        <Box
          sx={{
            mt: 4,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="colorButton" // Using custom variant from your theme
            onClick={() => {
              setOpenImageUploadModal(false);
              setOpenConfirmationModal(false);
            }}
            sx={{ width: '48%' }}
          >
            {t('selectImages.closeConfirmationModal.yes')}
          </Button>
          <Button
            variant="inverseButton" // Using custom variant from your theme
            onClick={() => setOpenConfirmationModal(false)}
            sx={{ width: '48%' }}
          >
            {t('selectImages.closeConfirmationModal.no')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CloseConfirmationModal;
