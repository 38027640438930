import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { modalStyle, ColorButton, InverseButton } from '../../../theme';
import { Typography } from '@mui/material';

const DeleteModal = ({
  modalTitle,
  openDeleteConfirmationDialog,
  setOpenDeleteConfirmationDialog,
  deleteMessage,
  handleConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={openDeleteConfirmationDialog}
      onClose={() => setOpenDeleteConfirmationDialog(false)}
    >
      <Box sx={{
        ...modalStyle,
        padding: '2rem',
        width: '100%',
        maxWidth: '400px',
      }}>
        <Stack spacing={3}>
          <Typography variant="h6" component="h2">
            {modalTitle}
          </Typography>
          <Typography variant="body1">
            {deleteMessage}
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <InverseButton
              onClick={() => setOpenDeleteConfirmationDialog(false)}
            >
              {t('Cancel')}
            </InverseButton>
            <ColorButton
              onClick={handleConfirm}
            >
              {t('Delete')}
            </ColorButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default DeleteModal;