import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import "./i18n";
import App from "./App";
import { Auth0ProviderWithConfig } from "./Authentication/auth0-provider";

// Scroll to top helper
const scrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

// Create router configuration
const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
    loader: scrollToTop,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0ProviderWithConfig>
      <RouterProvider router={router} />
    </Auth0ProviderWithConfig>
  </React.StrictMode>
);
