import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const { i18n } = useTranslation();

  useEffect(() => {
    const locale = i18n.language; // Get the current language from i18n
    loginWithRedirect({ui_locales: 'it'});
  }, [i18n.language, loginWithRedirect]); // Dependency array to ensure effect runs on language change

  return (
    <div style={{ height: "100vh" }}>
      <LoadingScreen
        header={i18n.t('authGuard.redirecting.header')}
        text={i18n.t('authGuard.redirecting.text')}
      />
    </div>
  );
}

export default Login;
