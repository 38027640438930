export const cls = (input) =>
  input
    .replace(/\s+/gm, " ")
    .split(" ")
    .filter((cond) => typeof cond === "string")
    .join(" ")
    .trim();

export const createCSV = (
  tableTrialIndex = null,
  projectTrials = null,
  projectImages,
  uploadedImages
) => {
  let csvFilename = "Trials";
  let csv = "";

  if (tableTrialIndex !== null) {
    if (projectTrials !== null) {
      csvFilename = projectTrials[tableTrialIndex].name;
    } else {
      csvFilename = "Unsaved_Trial";
    }
    // Get all unique headers except 'bounding_boxes'
    const headers = Array.from(
      new Set(
        projectImages[tableTrialIndex].flatMap((image) => Object.keys(image))
      )
    ).filter(
      (header) =>
        header !== "bounding_boxes" && header !== "trial_id" && header !== "_id"
    );
    csv += headers.join(",") + "\n";

    // Add data from each image
    csv += projectImages[tableTrialIndex]
      .map((image) => {
        return headers.map((header) => image[header]).join(",");
      })
      .join("\n");

    console.log(csv);
    downloadCSV(csv, csvFilename);
    return;
  } else {
    if (uploadedImages) {
        csvFilename = "Uploaded_Images";
    
        // Get all unique headers except 'bounding_boxes'
        const headers = Array.from(
            new Set(
            uploadedImages.flatMap((image) => Object.keys(image))
            )
        ).filter(
            (header) =>
            header !== "bounding_boxes" && header !== "trial_id" && header !== "_id"
        );
        csv += headers.join(",") + "\n";
    
        // Add data from each image
        csv += uploadedImages
            .map((image) => {
            return headers.map((header) => image[header]).join(",");
            })
            .join("\n");
    
        console.log(csv);
        downloadCSV(csv, csvFilename);
    } else {
      csvFilename = "All_Trials";

      // Get all unique headers except 'bounding_boxes'
      const headers = Array.from(
        new Set(
          projectImages.flatMap((trialImages) =>
            trialImages.flatMap((image) => Object.keys(image))
          )
        )
      ).filter(
        (header) =>
          header !== "bounding_boxes" &&
          header !== "trial_id" &&
          header !== "_id"
      );
      csv += headers.join(",") + "\n";

      // Add data from each image in each trial
      projectImages.forEach((trialImages) => {
        csv += trialImages
          .map((image) => {
            return headers.map((header) => image[header]).join(",");
          })
          .join("\n");
      });

      console.log(csv);
      downloadCSV(csv, csvFilename);
    }
  }
};

const downloadCSV = (csv, csvFilename) => {
  const element = document.createElement("a");
  const file = new Blob([csv], { type: "text/csv" });
  element.href = URL.createObjectURL(file);
  element.download = `${csvFilename}.csv`;
  document.body.appendChild(element);
  element.click();
};
