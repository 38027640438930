import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const location = useLocation();

    useEffect(() => {
        let isMounted = true;

        const checkAuth = async () => {
            if (!isLoading && !isAuthenticated && isMounted) {
                await loginWithRedirect({
                    appState: { returnTo: location.pathname },
                });
            }
        };

        checkAuth();

        return () => {
            isMounted = false;
        };
    }, [isLoading, isAuthenticated, loginWithRedirect, location]);

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return isAuthenticated ? children : null;
};

export default ProtectedRoute;