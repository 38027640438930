// theme.js

import { createTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#8ECEFA', // teal[2]
      main: '#13A4AD',  // teal[6]
      dark: '#0E777E',  // teal[7]
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#85CEFA', // blue[4]
      main: '#3D7A9F',  // blue[7]
      dark: '#264C64',  // blue[9]
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#F4AC45', // orange[5]
    },
    warning: {
      main: '#F7C072', // orange[4]
    },
    info: {
      main: '#4ECBD3', // teal[4]
    },
    success: {
      main: '#16BAC5', // teal[5]
    },
    grey: {
      50: '#F9F9F9',    // gray[1]
      100: '#CDCDCD',   // gray[2]
      200: '#A2A29F',   // gray[3]
      300: '#777773',   // gray[4]
      400: '#4C4C47',   // gray[5]
      500: '#3A3A36',   // gray[6]
      600: '#31312D',   // gray[7]
      700: '#282825',   // gray[8]
      800: '#1E1E1C',   // gray[9]
      900: '#1E1E1C',   // gray[10]
    },
    background: {
      default: '#FFFFFF',
      paper: '#F2F2F2',
      secondary: '#F0F8FF',
    },
    text: {
      primary: '#264C64', // Changed to dark blue for better contrast
    }
  },
  customBackgrounds: {
    secondaryGradient: {
      background: "linear-gradient(to bottom, #FFFFFF 0%, #C9E8FF 5%, #C9E8FF 95%, #FFFFFF 100%)",
    },
  
  },
  typography: {
    fontFamily: 'Avenir Next Cyr',
    h1: {
      fontSize: '35px',
      lineHeight: '48px',
      fontWeight: 500,
      color: '#264C64',
    },
    h2: {
      fontSize: '30px',
      lineHeight: '40px',
      fontWeight: 500,
      color: '#264C64',
    },
    h3: {
      fontSize: '25px',
      lineHeight: '34px',
      fontWeight: 500,
      color: '#264C64',
    },
    body1: {
      fontSize: '20px',
      lineHeight: '27px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    caption: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900, // Default MUI value
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    // Custom Button Variants
    MuiButton: {
      variants: [
        {
          props: { variant: 'colorButton' },
          style: {
            backgroundColor: '#13A4AD',
            color: '#FFFFFF',
            padding: '5px 5px',
            fontSize: '16px',
            textTransform: 'none',
            borderRadius: '7px',
            boxShadow: 'none',
            fontFamily: 'Avenir Next Cyr',
            '&:hover': {
              backgroundColor: '#16BAC5',
            },
            '&.Mui-disabled': {
              backgroundColor: '#86DBE1',
              color: '#777773',
            },
          },
        },
        {
          props: { variant: 'inverseButton' },
          style: {
            backgroundColor: '#FFFFFF',
            color: '#3D7A9F',
            textTransform: 'none',
            borderRadius: '7px',
            padding: '0px',
            fontFamily: 'Avenir Next Cyr',
            '&:hover': {
              color: '#85CEFA',
              backgroundColor: '#FFFFFF',
              borderBottom: '1px solid #FD9076',
            },
          },
        },
      ],
    },
    // Custom Typography Variants
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h1Bold' },
          style: {
            fontSize: '35px',
            lineHeight: '48px',
            fontWeight: 600,
            color: '#264C64',
          },
        },
        {
          props: { variant: 'h2Bold' },
          style: {
            fontSize: '30px',
            lineHeight: '40px',
            fontWeight: 600,
            color: '#264C64',
          },
        },
        {
          props: { variant: 'h3Bold' },
          style: {
            fontSize: '25px',
            lineHeight: '34px',
            fontWeight: 600,
            color: '#264C64',
          },
        },
        {
          props: { variant: 'body1Bold' },
          style: {
            fontSize: '20px',
            lineHeight: '27px',
            fontWeight: 600,
          },
        },
        {
          props: { variant: 'body2Bold' },
          style: {
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 600,
          },
        },
      ],
    },
    // Example of overriding a MUI component
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#13A4AD', // primary.main
          color: '#FFFFFF',
          padding: '20px 0',
        },
      },
    },
  },
});

// Modal Style
export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  bottom: 'auto',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  maxWidth: '99%',
  maxHeight: '97%',
  height: 'auto',
  backgroundColor: 'white',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  overflow: 'auto',
  borderRadius: '10px',
  transition: 'transform 0.3s ease-out',
};

// Styled Components

export const StyledLogo = styled('img')(({ theme }) => ({
  width: 80,
  height: 40,
  cursor: 'pointer',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
  [theme.breakpoints.down('sm')]: {
    width: 60,
    height: 30,
  },
}));

export const Link = styled(Button)(({ theme }) => ({
  color: '#FFFFFF',
  '&:hover': {
    color: theme.palette.primary.light,
  },
  textTransform: 'none',
  padding: '4px 8px',
  minWidth: 'auto',
}));

export const NavBarItemStyle = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&.active': {
    fontWeight: 'bold',
  },
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(1, 0.5),
    minWidth: 'auto',
  },
}));

// Since we've defined custom variants, you can use them directly with the Button component
// But if you prefer styled components, here's how you can define them

export const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#FFFFFF',
  padding: '5px 5px',
  fontSize: '16px',
  textTransform: 'none',
  borderRadius: '7px',
  boxShadow: 'none',
  fontFamily: 'Avenir Next Cyr',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.grey[500],
  },
}));

export const InverseButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  color: theme.palette.secondary.main,
  textTransform: 'none',
  borderRadius: '7px',
  padding: '0px',
  fontFamily: 'Avenir Next Cyr',
  '&:hover': {
    color: theme.palette.secondary.light,
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid #FD9076',
  },
}));
