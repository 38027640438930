import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { GetUserByID } from "./services/user.service";
import { GetProjects } from "./services/project.service";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import "./assets/fonts/stylesheet.css";
import { Box, CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import Home from "./pages/Home/Home";
import Profile from "./pages/Profile/Profile";
import Project from "./pages/Project/Project";
import Dashboard from "./pages/Dashboard/Dashboard";
import Pricing from "./pages/Pricing/Pricing";
import Products from "./pages/Products/Products";
import Contact from "./pages/Contact/Contact";
import Login from "./Authentication/Login";
import Logout from "./Authentication/Logout";
import { useRoutes } from "react-router-dom";
import Layout from "./Layout.jsx";
import ProtectedRoute from "./ProtectedRoute.jsx";
// import LoadingScreen from "./components/LoadingScreen/LoadingScreen";

// Google Tag Manager Initialization
const gtmID = process.env.REACT_APP_GTM_ID;
const environment = process.env.REACT_APP_ENV;
if (environment === "production") {
  TagManager.initialize({ gtmId: gtmID });
}

function App() {
  const { t } = useTranslation();
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);

  const fetchUserAndProjects = async (isMounted = true) => {
    if (!user) {
      if (isMounted) setIsLoading(false);
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      if (!isMounted) return;

      setToken(token);
      try {
        const { data } = await GetUserByID(
          token,
          user.sub.split("|")[1],
          user.email
        );

        if (isMounted && data) {
          setUserData(data);
        }
      } catch (e) {
        console.error(e);
      }

      try {
        const projectsData = await GetProjects(token, user.sub.split("|")[1]);
        if (isMounted) {
          setProjects(projectsData.data);
        }
      } catch (e) {
        console.error(e);
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (isMounted) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    fetchUserAndProjects(isMounted);

    return () => {
      isMounted = false;
    };
  }, [user, getAccessTokenSilently]);

  // Email verification check wrapper
  const EmailVerificationWrapper = ({ children }) => {
    if (user && user.email_verified === false) {
      return <VerifyEmail />;
    }
    return children;
  };

  const routes = useRoutes([
    {
      path: "/",
      element: (
        <Layout userData={userData} InfoUpdated={userData.InfoUpdated}>
          <Home />
        </Layout>
      ),
    },
    // Protected routes
    {
      path: "/profile",
      element: (
        <Layout
          userData={userData}
          InfoUpdated={userData.InfoUpdated}
          projects={projects}
          setProjects={setProjects}
        >
          <EmailVerificationWrapper>
            <ProtectedRoute>
              <Profile accessToken={token} user={user} />
            </ProtectedRoute>
          </EmailVerificationWrapper>
        </Layout>
      ),
    },
    {
      path: "/project/:projectID",
      element: (
        <Layout
          userData={userData}
          InfoUpdated={userData.InfoUpdated}
          projects={projects}
          setProjects={setProjects}
        >
          <EmailVerificationWrapper>
            <ProtectedRoute>
              <Project
                accessToken={token}
                user={user}
                setProjectDeleted={() => fetchUserAndProjects()}
              />
            </ProtectedRoute>
          </EmailVerificationWrapper>
        </Layout>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <Layout
          userData={userData}
          InfoUpdated={userData.InfoUpdated}
          projects={projects}
          setProjects={setProjects}
        >
          <EmailVerificationWrapper>
            <ProtectedRoute>
              <Dashboard
                userData={userData}
                user={user}
                accessToken={token}
                projects={projects}
                setProjects={setProjects}
              />
            </ProtectedRoute>
          </EmailVerificationWrapper>
        </Layout>
      ),
    },
    // Public routes
    {
      path: "/pricing",
      element: (
        <Layout userData={userData} InfoUpdated={userData.InfoUpdated}>
          <Pricing />
        </Layout>
      ),
    },
    {
      path: "/products",
      element: (
        <Layout userData={userData} InfoUpdated={userData.InfoUpdated}>
          <Products />
        </Layout>
      ),
    },
    {
      path: "/contact",
      element: (
        <Layout userData={userData} InfoUpdated={userData.InfoUpdated}>
          <Contact />
        </Layout>
      ),
    },
    {
      path: "/login",
      element: (
        <Layout userData={userData} InfoUpdated={userData.InfoUpdated}>
          <Login />
        </Layout>
      ),
    },
    {
      path: "/logout",
      element: (
        <Layout userData={userData} InfoUpdated={userData.InfoUpdated}>
          <Logout />
        </Layout>
      ),
    },
  ]);

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  return routes;
}

export default App;
