import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Container,
  Grid,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import './Pricing.css';
import { theme } from '../../theme';
import { ColorButton } from '../../theme';
import { motion } from "framer-motion";

const PricingCard = ({ title, description, buttonText, features, isHighlighted, buttonLink }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card 
      elevation={isHighlighted ? 8 : 2}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: isSmallScreen ? 'none' : 'translateY(-10px)',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        },
        borderRadius: '16px',
        overflow: 'hidden',
        border: isHighlighted ? `2px solid ${theme.palette.primary.main}` : 'none',
      }}
    >
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', p: { xs: 2, sm: 3, md: 4 } }}>
        <Typography variant="h4" component="h3" gutterBottom sx={{ mb: 2, fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' } }}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3, fontSize: { xs: '0.875rem', sm: '1rem' } }}>
          {description}
        </Typography>
        <Box sx={{ flexGrow: 1, mb: 3 }}>
          <ul style={{ padding: 0, listStyle: 'none', margin: 0 }}>
            {features.map((feature, index) => (
              <li key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '12px' }}>
                <CheckIcon color="primary" fontSize="small" sx={{ mr: 1, mt: '4px' }} />
                <Typography variant="body2" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                  {feature}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
        <ColorButton variant="contained" color="primary" sx={{ mt: 'auto', py: { xs: 1, sm: 1.5 }, fontSize: { xs: '0.875rem', sm: '1rem' } }} href={buttonLink}>
          {buttonText}
        </ColorButton>
      </CardContent>
    </Card>
  );
};

const Pricing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const plans = [
    {
      title: t("pricing.explorer.name"),
      description: t("pricing.explorer.desc"),
      buttonText: t("pricing.uploadNow"),
      features: [
        t("pricing.features.limitedDataProcessing"),
        t("pricing.features.experimentArchives"),
        t("pricing.features.automatedReportGeneration"),
        t("pricing.features.free"),
      ],
    },
    {
      title: t("pricing.academic.name"),
      description: t("pricing.academic.desc"),
      buttonText: t("pricing.getInTouch"),
      features: [
        t("pricing.features.unlimitedDataProcessing"),
        t("pricing.features.experimentArchives"),
        t("pricing.features.automatedReportCreation"),
      ],
      isHighlighted: true
    },
    {
      title: t("pricing.laboratory.name"),
      description: t("pricing.laboratory.desc"),
      buttonText: t("pricing.getInTouch"),
      features: [
        t("pricing.features.unlimitedDataProcessing"),
        t("pricing.features.experimentArchives"),
        t("pricing.features.automatedReportCreation"),
        t("pricing.features.teamCollaboration"),
      ],
    },
    {
      title: t("pricing.enterprise.name"),
      description: t("pricing.enterprise.desc"),
      buttonText: t("pricing.getInTouch"),
      features: [
        t("pricing.features.unlimitedDataProcessing"),
        t("pricing.features.experimentArchives"),
        t("pricing.features.automatedReportCreation"),
        t("pricing.features.teamCollaboration"),
        t("pricing.features.tailoredSolutions"),
      ],
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Box 
          sx={{ 
            bgcolor: 'background.default',
            pt: { xs: '80px', sm: '100px', md: '120px' }, // Adjust these values based on your navbar height
            pb: { xs: 4, sm: 6, md: 8 },
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Container maxWidth="xl">
            <Box 
              sx={{ 
                textAlign: 'center', 
                mb: { xs: 4, sm: 6, md: 8 },
                position: 'relative',
                zIndex: 1,
              }}
            >
              <Typography 
                variant="h1" 
                component="h1" 
                sx={{ 
                  mb: 2,
                  fontWeight: 'bold',
                  fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' },
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                {t("pricing.header")}
              </Typography>
              <Typography 
                variant="h5" 
                sx={{ 
                  maxWidth: '800px',
                  mx: 'auto',
                  color: 'text.secondary',
                  fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
                }}
              >
                {t("pricing.subHeader")}
              </Typography>
            </Box>
            <Grid 
              container 
              spacing={{ xs: 2, sm: 3, md: 4 }} 
              justifyContent="center"
              sx={{ position: 'relative', zIndex: 1 }}
            >
              {plans.map((plan, index) => (
                <Grid item key={index} xs={12} sm={6} md={6} lg={3}>
                  <PricingCard {...plan} buttonLink={index === 0 ? '/dashboard' : 'https://app.apollo.io/#/meet/ali_mehdi_e71'} />
                </Grid>
              ))}
            </Grid>
          </Container>
          <Box
            sx={{
              position: 'absolute',
              top: '-10%',
              left: '-5%',
              width: '120%',
              height: '120%',
              background: `radial-gradient(circle, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
              opacity: 0.5,
              zIndex: 0,
            }}
          />
        </Box>
      </motion.div>
    </ThemeProvider>
  );
};

export default Pricing;