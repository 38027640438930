import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Advisors() {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const advisors = [
    { name: "Margaret Bywater-Ekegärd", role: t("advisors.advisor1.role"), company: t("advisors.advisor1.company") },
    { name: "Alex Benjamin", role: t("advisors.advisor2.role"), company: t("advisors.advisor2.company") },
    { name: "Donald Mclaren", role: t("advisors.advisor3.role"), company: t("advisors.advisor3.company") },
    { name: "Benjamin Scott", role: t("advisors.advisor4.role"), company: t("advisors.advisor4.company") },
  ];

  return (
    <Box sx={{ maxWidth: 'md', mx: 'auto', my: 4 }}>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="advisors-content"
          id="advisors-header"
        >
          <Typography variant="h5">{t("advisors.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {advisors.map((advisor, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ 
                    pt: '100%', 
                    position: 'relative', 
                    overflow: 'hidden' 
                  }}>
                    <Box
                      component="img"
                      src={require(`../../../assets/images/Advisors/${advisor.name.split(' ')[0].toLowerCase()}.jpeg`)}
                      alt={advisor.name}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box>
                      <Typography gutterBottom variant="h6" component="div">
                        {advisor.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {advisor.role}
                      </Typography>
                    </Box>
                    {advisor.company && (
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        {advisor.company}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default Advisors;