import { Box, Typography } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";


const StatCard = ({
    title,
    value,
    icon,
    showPopup,
    onMouseEnter,
    onMouseLeave,
}) => (
    <Box
        sx={{
            bgcolor: "white",
            borderRadius: 2,
            p: 3,
            boxShadow: 1,
            position: "relative",
            "&:hover": {
                boxShadow: 3,
            },
        }}
    >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">{title}</Typography>
            <Box onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {icon}
            </Box>
        </Box>
        <Typography variant="h4">{value}</Typography>
        <AnimatePresence>
            {showPopup && (
                <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.2 }}
                    style={{
                        position: "absolute",
                        top: "100%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundColor: "white",
                        border: "1px solid",
                        borderColor: "grey.300",
                        borderRadius: "4px",
                        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                        padding: "8px",
                        zIndex: 1000,
                        maxWidth: "300px",
                    }}
                >
                    <Typography variant="body2" color="text.secondary">
                        {title}
                    </Typography>
                </motion.div>
            )}
        </AnimatePresence>
    </Box>
);

export default StatCard;