import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Skeleton,
  ThemeProvider,
} from "@mui/material";
import { theme } from "../../../../theme";
import video from "../../../../assets/videos/upload_demo.mp4";

const HowItWorks = () => {
  const { t, i18n } = useTranslation();
  const [steps, setSteps] = useState([]);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setSteps([
      {
        index: 1,
        keyword: t("howItWorks.step1Keyword"),
        info: t("howItWorks.step1Text"),
      },
      {
        index: 2,
        keyword: t("howItWorks.step2Keyword"),
        info: t("howItWorks.step2Text"),
      },
      {
        index: 3,
        keyword: t("howItWorks.step3Keyword"),
        info: t("howItWorks.step3Text"),
      },
    ]);
  }, [t, i18n.language]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && videoRef.current) {
          videoRef.current.load();
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: "background.primary" }}>
        <Box
          sx={{
            maxWidth: "lg",
            mx: "auto",
            py: { xs: 6, md: 15 },
            px: { xs: 2, sm: 4, md: 6 },
          }}
        >
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "grayScale.2",
              mb: { xs: 4, md: 6 },
            }}
          >
            {t("howItWorks.title")}
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={5}>
              <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                {steps.map((step, index) => (
                  <Box
                    key={index}
                    sx={{
                      mb: 4,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: { xs: "center", md: "flex-start" },
                    }}
                  >
                    <Typography
                      variant="h3"
                      component="div"
                      sx={{
                        fontWeight: "bold",
                        color: "primary.main",
                        mb: 1,
                        textAlign: { xs: "center", md: "left" },
                      }}
                    >
                      {step.index}. {step.keyword}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "grayScale.4",
                        textAlign: { xs: "center", md: "left" },
                      }}
                    >
                      {step.info}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box
                sx={{
                  position: "relative",
                  paddingTop: "56.25%", // 16:9 aspect ratio
                  height: 0,
                  overflow: "hidden",
                  borderRadius: 2,
                  boxShadow: 4,
                }}
              >
                {!videoLoaded && (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100%"
                    animation="wave"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  />
                )}
                <video
                  ref={videoRef}
                  autoPlay
                  loop
                  muted
                  playsInline
                  onLoadedData={handleVideoLoad}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    display: videoLoaded ? "block" : "none",
                  }}
                >
                  <source src={video} type="video/mp4" />
                </video>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default HowItWorks;
