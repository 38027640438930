import React, { useState, useEffect } from "react";
import {
    TextField,
    LinearProgress,
    Button,
    Box,
    Typography,
    Grid,
    IconButton,
    Paper
} from "@mui/material";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { BloodSmearRBCTypes, BloodSmearWBCTypes } from "../../../utils/enums";
import ImageEditor from "../../ImageEditor/ImageEditor";
import { useTranslation } from "react-i18next";

function ReviewImages({
    adjustedBoundingBoxes,
    setAdjustedBoundingBoxes,
    newCounts,
    setNewCounts,
    setDilutionFactor,
    imagesProcessed,
    fileList,
    fileNames,
    setfileNames,
    assayType,
    dilutionFactor,
    boundingBoxesList,
    setConfirmResults,
    sensitivity,
    notes,
    setNotes,
    bandMeasurements,
    setBandMeasurements,
    setClass0MeasurementsInClass4,
    class0MeasurementsInClass4,
    setSelectedTemplateLadder,
    selectedTemplateLadder,
    selectedWells,
    setSelectedWells,
    BPValueToFind,
    setBPValueToFind,
    BPMarginOfError,
    setBPMarginOfError,
}) {
    const [index, setIndex] = useState(0);
    const [base64Images, setBase64Images] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const convertImagesToBase64 = async () => {
            const promises = fileList.map((file) => getBase64Image(file));
            const base64Files = await Promise.all(promises);
            setBase64Images(base64Files);
        };

        convertImagesToBase64();
    }, [fileList]);

    const getBase64Image = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                // Remove the prefix
                const base64String = reader.result.split(",")[1];
                resolve(base64String);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const getCellTypes = (assay) => {
        let cellTypes = [];
        if (assay === "Smear") {
            Object.keys(BloodSmearRBCTypes).forEach((key) => {
                cellTypes.push(BloodSmearRBCTypes[key]);
            });
        } else if (assay === "Smear WBC") {
            Object.keys(BloodSmearWBCTypes).forEach((key) => {
                cellTypes.push(BloodSmearWBCTypes[key]);
            });
        } else if (assay === "GFP/OFP") {
            cellTypes.push("GFP");
            cellTypes.push("OFP");
        } else if (assay === "Membrane") {
            cellTypes.push("E. Coli");
            cellTypes.push("Other Coliform");
        } else {
            cellTypes.push("Colony");
        }
        return cellTypes;
    };

    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
                {/* top Side */}
                <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                    <Box>
                        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    mb: 2,
                                }}
                            >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <IconButton
                                        onClick={() => {
                                            if (index > 0) {
                                                setIndex(index - 1);
                                            }
                                        }}
                                    >
                                        <KeyboardArrowLeftRoundedIcon />
                                    </IconButton>
                                    <Typography variant="body1" sx={{ mx: 2 }}>
                                        {index + 1} {t("selectImages.reviewImages.pagination.of")}{" "}
                                        {imagesProcessed}
                                    </Typography>
                                    <IconButton
                                        onClick={() => {
                                            if (index < imagesProcessed - 1) {
                                                setIndex(index + 1);
                                            }
                                        }}
                                    >
                                        <KeyboardArrowRightRoundedIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            {fileList.length !== imagesProcessed && (
                                <Box sx={{ width: "50%" }}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={(imagesProcessed / fileList.length) * 100}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                            {base64Images.length > 0 &&
                                boundingBoxesList.length > 0 &&
                                boundingBoxesList[index] && (
                                    <ImageEditor
                                        imageData={{
                                            bounding_boxes: boundingBoxesList[index],
                                            sensitivity: sensitivity[index],
                                            assay_type: assayType,
                                        }}
                                        setSensitivity={sensitivity}
                                        newCounts={newCounts}
                                        setNewCounts={setNewCounts}
                                        setAdjustedBoundingBoxes={setAdjustedBoundingBoxes}
                                        adjustedBoundingBoxes={adjustedBoundingBoxes}
                                        multiclassModel={
                                            assayType === "Smear" ||
                                            assayType === "Smear WBC" ||
                                            assayType === "GFP/OFP"
                                        }
                                        accessToken={null}
                                        cellTypes={getCellTypes(assayType)}
                                        showHelperMessage={false}
                                        horizontalControls={true}
                                        imageFile={base64Images[index]}
                                        index={index}
                                        ImageIndex={index}
                                        bandMeasurements={bandMeasurements}
                                        setBandMeasurements={setBandMeasurements}
                                        setSelectedTemplateLadder={setSelectedTemplateLadder}
                                        selectedTemplateLadder={selectedTemplateLadder}
                                        setClass0MeasurementsInClass4={
                                            setClass0MeasurementsInClass4
                                        }
                                        class0MeasurementsInClass4={class0MeasurementsInClass4}
                                        selectedWells={selectedWells}
                                        setSelectedWells={setSelectedWells}
                                        BPValueToFind={BPValueToFind}
                                        setBPValueToFind={setBPValueToFind}
                                        BPMarginOfError={BPMarginOfError}
                                        setBPMarginOfError={setBPMarginOfError}
                                    />
                                )}
                        </Box>
                    </Box>
                </Grid>
                {/* bottom Side */}
                <Grid item xs={12} md={12} sx={{ mb: 8, mt: 2, position: "relative" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label={t("selectImages.reviewImages.fields.imageName")}
                                variant="standard"
                                fullWidth
                                value={
                                    fileNames[index] ? fileNames[index].split(".")[0] : ""
                                }
                                onChange={(e) => {
                                    const newImageNames = [...fileNames];
                                    newImageNames[index] =
                                        e.target.value + "." + newImageNames[index].split(".")[1];
                                    setfileNames(newImageNames);
                                }}
                            />
                        </Grid>
                        {assayType !== "Gel Electrophoresis" &&
                            dilutionFactor.length > 0 &&
                            dilutionFactor[index] !== undefined && (
                                <Grid item xs={12}>
                                    <TextField
                                        label={t(
                                            "selectImages.reviewImages.fields.dilutionFactor"
                                        )}
                                        variant="standard"
                                        fullWidth
                                        type="number"
                                        value={
                                            dilutionFactor[index] === null
                                                ? ""
                                                : dilutionFactor[index]
                                        }
                                        onChange={(e) => {
                                            const newDilutionFactor = [...dilutionFactor];
                                            newDilutionFactor[index] = e.target.value;
                                            setDilutionFactor(newDilutionFactor);
                                        }}
                                    />
                                </Grid>
                            )}
                        <Grid item xs={12}>
                            <TextField
                                label={t("selectImages.reviewImages.fields.notes")}
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                value={notes[index]}
                                onChange={(e) => {
                                    const newNotes = [...notes];
                                    newNotes[index] = e.target.value;
                                    setNotes(newNotes);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {imagesProcessed === fileList.length && (
                <Paper sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    bottom: "0px",
                    right: "0px",
                    left: "0px",
                    backgroundColor: "background.paper",
                    padding: "10px",
                    boxShadow: 5,
                }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setConfirmResults();
                        }}
                    >
                        {t("selectImages.reviewImages.buttons.finish")}
                    </Button>
                </Paper>
            )}
        </Box>
    );
}

export default ReviewImages;
