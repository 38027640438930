import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ThemeProvider } from "@mui/material/styles";
import { theme, ColorButton } from "../../../../theme";

const Landing = () => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={6} sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center',
            p: { xs: 3, sm: 4, md: 5, lg: 6 },
            textAlign: { xs: 'center', md: 'left' } // Center text on xs and sm screens
          }}>
            <Box>
              <Typography 
                variant="h1" 
                component="h1" 
                gutterBottom
                sx={{ 
                  fontWeight: "bold", 
                  color: "primary.main",
                  mb: 1,
                  fontSize: { xs: '2.2rem', sm: '3rem', md: '3.5rem', lg: '4.5rem' },
                  lineHeight: { xs: '2.5rem', sm: '3.5rem', md: '4rem', lg: '5rem' }
                }}
              >
                {t("landing.title")}
              </Typography>
              <Typography 
                variant="h4" 
                sx={{ 
                  color: "text.secondary",
                  mb: 3,
                  fontSize: { xs: '1.1rem', sm: '1.3rem', md: '1.5rem', lg: '1.8rem' }
                }}
              >
                {t("landing.subtitle")}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}> {/* Center button on small screens */}
                <ColorButton
                  variant="contained"
                  size="large"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => {
                    window.location.href = "/dashboard";
                  }}
                  sx={{ 
                    mb: 1,
                    py: 1.5,
                    px: 4,
                    fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem', lg: '1.3rem' }
                  }}
                >
                  {t("landing.getStarted")}
                </ColorButton>
              </Box>
              <Typography 
                variant="body1" 
                sx={{ 
                  color: "text.secondary",
                  fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' }
                }}
              >
                {t("landing.freeDemo")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            p: { xs: 2, sm: 3, md: 4 },
            height: { xs: 'auto', md: '100%' }
          }}>
            <Box 
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                maxHeight: '50vh',
              }}
            >
              <Box
                component="img"
                src={require("../../../../assets/images/light-main.png")}
                alt={t("landing.imageAlt")}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  display: 'block',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default Landing;