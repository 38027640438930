import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Box,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../../../theme";

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);
  const [faqValues, setFaqValues] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setFaqValues([
      { question: t("faq.question1"), answer: t("faq.answer1") },
      { question: t("faq.question2"), answer: t("faq.answer2") },
      { question: t("faq.question3"), answer: t("faq.answer3") },
      { question: t("faq.question4"), answer: t("faq.answer4") },
      { question: t("faq.question5"), answer: t("faq.answer5") },
    ]);
  }, [t, i18n.language]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ ...theme.customBackgrounds.secondaryGradient }}>
      <Typography variant="h3" component="h2" align="center" gutterBottom fontWeight="bold" pt={4}>
          {t("faq.title")}
        </Typography>
        <Box sx={{ m: 4 }}>
          {faqValues.map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              elevation={0}
              sx={{
                "&:before": {
                  display: "none",
                },
                "&.Mui-expanded": {
                  margin: 0,
                },
                backgroundColor: "transparent",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                <Typography sx={{ fontSize: "1.1rem", fontWeight: 500 }}>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Typography variant="body2" align="center" sx={{ mt: 4, color: "text.secondary", pb: 4 }}>
          {t("faq.answerNotFound") + " "}
          <Link
            href="/contact"
            underline="hover"
            sx={{
              color: "primary.main",
              "&:hover": {
                color: "primary.dark",
              },
            }}
          >
            {t("faq.answerNotFoundLink")}
          </Link>
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default FAQ;
