import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, ThemeProvider } from "@mui/material";
import { theme } from "../../../../theme";

// Import images
import petriIcon from "../../../../assets/images/Products/petri-icon.png";
import microscopyIcon from "../../../../assets/images/Products/microscopy-icon.png";
import egelIcon from "../../../../assets/images/Products/egel-icon.png";
import petriProd from "../../../../assets/images/Products/petri-prod.png";
import microscopyProd from "../../../../assets/images/Products/microscopy-prod.png";
import egelProd from "../../../../assets/images/Products/egel-prod.png";

const Products = () => {
  const { t } = useTranslation();
  const productsData = [
    {
      id: "petri-dish-analysis",
      icon: petriIcon,
      image: petriProd,
      titleKey: "products-home.microbiology.title",
      textKey: "products-home.microbiology.text",
      includedKey: "products-home.microbiology.included",
    },
    {
      id: "microscopy",
      icon: microscopyIcon,
      image: microscopyProd,
      titleKey: "products-home.hematology.title",
      textKey: "products-home.hematology.text",
      includedKey: "products-home.hematology.included",
    },
    {
      id: "egel",
      icon: egelIcon,
      image: egelProd,
      titleKey: "products-home.drugDevelopment.title",
      textKey: "products-home.drugDevelopment.text",
      includedKey: "products-home.drugDevelopment.included",
    },
  ];

  const [selectedProduct, setSelectedProduct] = useState(productsData[0]);
  const [isImageFading, setIsImageFading] = useState(false);

  useEffect(() => {
    setIsImageFading(true);
    const timer = setTimeout(() => setIsImageFading(false), 300);
    return () => clearTimeout(timer);
  }, [selectedProduct]);

  const handleIconClick = (productId) => {
    const product = productsData.find((p) => p.id === productId);
    setSelectedProduct(product);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: "background.primary", py: 6 }}>
        <Typography sx={{ color: "primary.main", textAlign: "center", mb: 3 }}>
          {t("products-home.ourProducts")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 3, md: 6 },
            alignItems: "center",
            justifyContent: "center",
            px: { xs: 2, sm: 4, md: 6 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              justifyContent: "space-between",
              gap: 3,
              width: { xs: "100%", md: "auto" },
            }}
          >
            {productsData.map((product) => (
              <Box
                key={product.id}
                onClick={() => handleIconClick(product.id)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  gap: 1,
                  cursor: "pointer",
                  opacity: selectedProduct.id === product.id ? 1 : 0.5,
                  transition: "opacity 0.3s",
                  "&:hover": { opacity: 0.8 },
                }}
              >
                <img
                  src={product.icon}
                  alt={t(product.titleKey)}
                  style={{ width: 75, height: "auto" }}
                />
                <Typography variant="body2">{t(product.titleKey)}</Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              maxWidth: { xs: 300, sm: 400, md: 500 },
              height: { xs: 200, sm: 300, md: 400 },
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: isImageFading ? 0 : 1,
                transition: "opacity 0.3s ease-in-out",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={selectedProduct.image}
                alt={t(selectedProduct.titleKey)}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              gap: 2,
              maxWidth: { xs: "100%", md: 400 },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography variant="h3">{t(selectedProduct.titleKey)}</Typography>
            <Typography variant="body1">
              {t(selectedProduct.textKey)}
            </Typography>
            <Box
              component="ul"
              sx={{
                listStyleType: "disc",
                pl: 3,
                alignSelf: { xs: "center", md: "flex-start" },
                textAlign: { xs: "left", md: "left" },
              }}
            >
              {t(selectedProduct.includedKey, { returnObjects: true }).map(
                (item, index) => (
                  <Typography component="li" key={index} variant="body2">
                    {item}
                  </Typography>
                )
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Products;
