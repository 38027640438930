import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import SponsorsCarousel from "./Components/Carousel/Carousel";
import Landing from "./Components/landing/landing";
import Products from "./Components/products/products-home";
import Stats from "./Components/stats/stats";
import HowItWorks from "./Components/howItWorks/HowItWorks";
import FAQ from "./Components/faq/faq";
import boxesJSON from "./boxes.json";
import demoPic from "./home-demo.jpg";

const FadeSection = ({ children }) => {
  const [opacity, setOpacity] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const intersectionRatio = entry.intersectionRatio;
        const viewportHeight = window.innerHeight;
        const elementHeight = entry.boundingClientRect.height;
        const elementCenter = entry.boundingClientRect.top + elementHeight / 2;
        const distanceFromCenter = Math.abs(viewportHeight / 2 - elementCenter);
        const maxDistance = viewportHeight / 2 + elementHeight / 2;
        const normalizedDistance = 1 - (distanceFromCenter / maxDistance);
        const newOpacity = 0.1 + normalizedDistance * 0.95;
        setOpacity(newOpacity);
      },
      {
        threshold: new Array(101).fill(0).map((_, i) => i / 100),
        root: null,
        rootMargin: "0px",
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <motion.div ref={ref} initial={{ opacity: 0 }} animate={{ opacity }} transition={{ duration: 0.3 }}>
      {children}
    </motion.div>
  );
};

const Home = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [base64String, setBase64String] = useState("");
  const [retrieveImage, setRetrieveImage] = useState(false);
  const [boxes, setBoxes] = useState([]);

  const img = new Image();
  img.src = demoPic;

  useEffect(() => {
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      setBase64String(canvas.toDataURL().split(",")[1]);
    };
  }, [retrieveImage]);

  useEffect(() => {
    setBoxes(boxesJSON);
  }, []);

  useEffect(() => {
    if (!base64String) setRetrieveImage(true);
  }, [base64String]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ backgroundColor: "white" }}>
      <FadeSection>
        <Landing />
      </FadeSection>
      <FadeSection>
        <SponsorsCarousel />
      </FadeSection>
      <FadeSection>
        <Products />
      </FadeSection>
      <FadeSection>
        <Stats base64String={base64String} boxes={boxes} />
      </FadeSection>
      <FadeSection>
        <HowItWorks />
      </FadeSection>
      <FadeSection>
        <FAQ />
      </FadeSection>
    </motion.div>
  );
};

export default Home;
