import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Alert,
    CircularProgress
} from '@mui/material';
import { DeleteProject } from '../../../../services/project.service';
import { useNavigate } from 'react-router-dom';
import {
    theme,
    modalStyle,
    ColorButton,
    InverseButton
} from '../../../../theme';
import { ThemeProvider } from '@mui/material/styles';

const contentStyle = {
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
};

const buttonContainerStyle = {
    display: 'flex',
    gap: 2,
    justifyContent: 'flex-end',
    mt: 3,
};

const DeleteProjectModal = ({
    openDeleteProjectModal,
    setOpenDeleteProjectModal,
    projectID,
    projectTitle,
    accessToken,
    setProjectDeleted
}) => {
    const { t } = useTranslation();
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    const [projectName, setProjectName] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);

    const navigate = useNavigate();

    const closeProjectDeleteModal = () => {
        if (!isDeleting) {
            setOpenDeleteProjectModal(false);
            setSeverity('');
            setMessage('');
            setProjectName('');
        }
    };

    const deleteProject = async () => {
        setIsDeleting(true);
        setSeverity('');
        setMessage('');

        try {
            let formData = new FormData();
            formData.append("project_id", projectID);
            await DeleteProject(accessToken, formData).then(() => {
                setProjectDeleted();
            });
            navigate('/dashboard');
        } catch (error) {
            setIsDeleting(false);
            setSeverity('error');
            setMessage(t('deleteModal.error'));
        }
    };

    const handleCorrectProjectName = () => {
        if (projectName === projectTitle) {
            deleteProject();
        } else {
            setSeverity('error');
            setMessage(t('deleteModal.errorProjectNameMismatch'));
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={openDeleteProjectModal}
                onClose={closeProjectDeleteModal}
                aria-labelledby="delete-project-modal"
                aria-describedby="delete-project-confirmation"
            >
                <Box sx={{
                    ...modalStyle,
                    width: { xs: '90%', sm: '60%' },
                    maxWidth: '600px'
                }}>
                    <Box sx={contentStyle}>
                        <Typography variant="h2" color="text.primary" gutterBottom>
                            {t('deleteModal.title')}
                        </Typography>

                        <Typography variant="body1" color="text.primary" gutterBottom>
                            {t('deleteModal.confirmationMessage', { projectTitle })}
                        </Typography>

                        <Typography variant="body1" color="text.primary" gutterBottom>
                            {t('deleteModal.typeToConfirm', { projectTitle })}
                        </Typography>

                        <TextField
                            fullWidth
                            variant="outlined"
                            size="medium"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            disabled={isDeleting}
                            sx={{
                                mt: 1,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.grey[200],
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                }
                            }}
                        />

                        {severity && (
                            <Alert
                                severity={severity}
                                sx={{
                                    mt: 1,
                                    '& .MuiAlert-icon': {
                                        color: theme.palette.error.main
                                    }
                                }}
                            >
                                {message}
                            </Alert>
                        )}

                        <Box sx={buttonContainerStyle}>
                            <InverseButton
                                onClick={closeProjectDeleteModal}
                                disabled={isDeleting}
                            >
                                {t('common.cancel')}
                            </InverseButton>
                            {isDeleting ? (
                                <CircularProgress
                                    size={36}
                                    sx={{
                                        color: theme.palette.primary.main,
                                        // Match button height to maintain layout
                                        my: '5px'
                                    }}
                                />
                            ) : (
                                <ColorButton
                                    onClick={handleCorrectProjectName}
                                >
                                    {t('common.delete')}
                                </ColorButton>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </ThemeProvider>
    );
};

export default DeleteProjectModal;