import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    List,
    ListItemButton,
    Divider,
    FormControl,
    InputLabel,
    Select,
    Box,
    Typography,
    MenuItem
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { InverseButton } from '../../../theme';
import Status from '../../../components/Status/Status';
import { useNavigate } from 'react-router-dom';

const ProjectList = ({ projects, setSortingMethod, sortingMethod, handleCreateProject }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Box sx={{ bgcolor: 'white', borderRadius: 2, overflow: 'hidden', boxShadow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 3 }}>
                <Typography variant="h6">{t("dashboard.projectsTitle")}</Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <InverseButton
                        startIcon={<AddIcon />}
                        onClick={handleCreateProject}
                    >
                        {t("dashboard.createNew")}
                    </InverseButton>
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="sorting-label">{t("dashboard.sortBy")}</InputLabel>
                        <Select
                            labelId="sorting-label"
                            value={sortingMethod}
                            label={t("dashboard.sortBy")}
                            onChange={(e) => setSortingMethod(e.target.value)}
                        >
                            <MenuItem value="Status">{t("dashboard.status")}</MenuItem>
                            <MenuItem value="Title">{t("dashboard.title")}</MenuItem>
                            <MenuItem value="Date">{t("dashboard.dateStarted")}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <Divider />
            <List sx={{ p: 0 }}>
                {projects && projects.length > 0 ? (
                    projects.map((project, index) => (
                        <React.Fragment key={project._id}>
                            <ListItemButton
                                sx={{
                                    bgcolor: 'background.default',
                                    '&:hover': {
                                        bgcolor: 'action.hover',
                                    },
                                }}
                                onClick={() => {
                                    navigate(`/project/${project._id}`);
                                }}
                            >
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography variant="subtitle1" color="secondary.main">
                                        {project.project_name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {project.project_description}
                                    </Typography>
                                </Box>
                                <Status
                                    progress={
                                        project.project_trials && project.project_trials.length > 0
                                            ? t("dashboard.inProgress")
                                            : t("dashboard.notStarted")
                                    }
                                />
                            </ListItemButton>
                            <Divider />
                        </React.Fragment>
                    ))
                ) : (
                    <Box sx={{ textAlign: 'center', py: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            {t("dashboard.noProjects")}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {t("dashboard.noProjectsDescription")}
                        </Typography>
                    </Box>
                )}
            </List>
        </Box>
    );
}

export default ProjectList;