import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const CreateProject = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/project/createProject`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const GetProject = async (accessToken, projectID) => {
  const config = {
    url: `${apiServerUrl}/project/getProject/${projectID}`,
    method: "GET",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const CreateTrial = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/project/createTrial`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const GetProjects = async (accessToken, id) => {
  const config = {
    url: `${apiServerUrl}/project/getUsersProjects/${id}`,
    method: "GET",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const SaveToProject = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/project/saveToTrial`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const SendInvite = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/project/sendInvite`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const EditProject = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/project/editProject`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const EditProjectTrials = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/project/editProjectTrials`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const GetProjectImage = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/project/getProjectImage`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const DeleteProject = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/project/deleteProject`,
    method: "DELETE",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const DeleteProjectTrial = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/project/deleteProjectTrial`,
    method: "DELETE",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const UpdateImage = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/project/updateImage`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const DeleteImage = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/project/deleteImage`,
    method: "DELETE",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const GetNotifications = async (accessToken, email) => {
  const config = {
    url: `${apiServerUrl}/project/getInvites/${email}`,
    method: "GET",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const SendInviteResponse = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/project/sendInviteResponse`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};
