import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Alert,
  TextField,
  Grid,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import CloseIcon from "@mui/icons-material/Close";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CreateTrial } from "../../services/project.service";
import { ColorButton, theme } from "../../theme";
import { useTranslation } from 'react-i18next';

export default function CreateTrialModal({
  selectedProjectID,
  accessToken,
  userID,
  openCreateTrialFlow,
  setOpenCreateTrialFlow,
  setProjectTrials,
  setProjectImages,
  onCreateTrial,
}) {
  const { t } = useTranslation();
  const muiTheme = useTheme();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [trialName, setTrialName] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');
  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);

  const onCreateTrialFormSubmit = () => {
    setCreateButtonDisabled(true);
    setLoading(true);

    let errors = false;

    if (selectedProjectID === '') {
      errors = true;
      setSeverity('error');
      setMessage(t('createTrial.refreshPage'));
      setLoading(false);
    }

    if (startDate === null || trialName === '') {
      errors = true;
      setSeverity('error');
      setMessage(t('createTrial.requiredFields'));
      setLoading(false);
    }
    if (!errors && endDate != null && endDate < startDate) {
      errors = true;
      setSeverity('error');
      setMessage(t('createTrial.endDateError'));
      setLoading(false);
    }

    if (!errors) {
      // Call API to create trial
      let formData = new FormData();
      formData.append('project_id', selectedProjectID);
      formData.append('start_date', startDate);
      if (endDate) {
        formData.append('end_date', endDate);
      }
      formData.append('name', trialName);
      formData.append('trial_notes', notes);
      formData.append('trial_creator', userID);

      setLoading(true);
      CreateTrial(accessToken, formData).then((response) => {
        if (response.error) {
          setSeverity('error');
          setMessage(t('createTrial.createError'));

          setTimeout(() => {
            setSeverity('');
            setMessage('');
            setLoading(false);
          }, 3000);
        } else {
          setMessage(t('createTrial.createSuccess'));
          setSeverity('success');
          // add new trial to projectTrials
          setOpenCreateTrialFlow(false);
          const newTrial = {
            _id: response.data._id, name: trialName, start_date: startDate.toISOString()
            , end_date: endDate ? endDate.toISOString() : '', notes: notes

          };
          if (setProjectImages !== undefined) {
            setProjectImages((prevImages) => {
              const newImages = prevImages;
              newImages[newImages.length] = [];
              return newImages;
            });
          }
          if (setProjectTrials !== undefined) {
            setProjectTrials((prevTrials) => {
              const newTrials = prevTrials;
              if (newTrials === undefined) {
                return [newTrial];
              }
              newTrials[newTrials.length] = newTrial;
              return newTrials;
            });
          }
          if (onCreateTrial !== undefined) {
            onCreateTrial(newTrial);
          }
          setTimeout(() => {
            setSeverity('');
            setMessage('');
            setLoading(false);
          }, 3000);
        }
      }
      );
    } else {
      setCreateButtonDisabled(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={openCreateTrialFlow}
        onClose={() => setOpenCreateTrialFlow(false)}
        fullWidth
        maxWidth="sm"
        scroll="paper"
        aria-labelledby="create-trial-modal-title"
      >
        <DialogTitle
          id="create-trial-modal-title"
          sx={{
            bgcolor: muiTheme.palette.primary.main,
            color: muiTheme.palette.primary.contrastText,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography >{t('createTrial.title')}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenCreateTrialFlow(false)}
            sx={{ color: muiTheme.palette.primary.contrastText }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t('createTrial.subtitle')}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={`${t('createTrial.trialName')}*`}
                      variant="outlined"
                      fullWidth
                      value={trialName}
                      onChange={(e) => setTrialName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label={`${t('createTrial.startDate')}*`}
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label={t('createTrial.endDate')}
                      value={endDate}
                      onChange={(newValue) => {
                        setEndDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t('createTrial.notes')}
                      variant="outlined"
                      fullWidth
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {severity && message && (
                      <Alert severity={severity}>{message}</Alert>
                    )}
                    {createButtonDisabled ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      <ColorButton
                        variant="contained"
                        onClick={onCreateTrialFormSubmit}
                        disabled={createButtonDisabled}
                      >
                        {t('createTrial.create')}
                      </ColorButton>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </LocalizationProvider>
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

