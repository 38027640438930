import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, ThemeProvider, Grid, useMediaQuery } from "@mui/material";
import { theme } from "../../../../theme";
import BBoxEditor from "../../../../components/BBoxEditor/BBoxEditor";

const Stats = ({ base64String, boxes }) => {
  const { t } = useTranslation();
  const [sliderValue, setSliderValue] = useState(100);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const stat_data = [
    {
      data: "2.5X",
      descKey: "stats.stat1Text",
    },
    {
      data: "8",
      descKey: "stats.stat3Text",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box 
        sx={{ 
          ...theme.customBackgrounds.secondaryGradient,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: { xs: 3, sm: 4, md: 5 },
        }}
      >
        <Grid 
          container 
          spacing={2} 
          sx={{ 
            maxWidth: '1000px',
            margin: '0 auto'
          }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: "grayScale.2",
                  mb: 1,
                  fontWeight: "bold",
                  fontSize: { xs: '22px', md: '28px' },
                }}
              >
                {t("stats.title")}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  color: "grayScale.3",
                  fontSize: { xs: '14px', md: '18px' },
                }}
              >
                {t("stats.titleSubtext")}
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                {stat_data.map((item, idx) => (
                  <Grid item xs={6} key={idx}>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="h3"
                        sx={{
                          color: "primary.main",
                          mb: 0.5,
                          fontWeight: "bold",
                          fontSize: { xs: '18px', md: '22px' },
                        }}
                      >
                        {item.data}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "grayScale.4",
                          fontSize: { xs: '12px', md: '14px' },
                        }}
                      >
                        {t(item.descKey)}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                maxHeight: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {base64String && (
                <BBoxEditor
                  horizontalControls={false}
                  cellTypes={["colonies"]}
                  accessToken={null}
                  imageData={{
                    bounding_boxes: boxes,
                    sensitivity: sliderValue,
                  }}
                  imageFile={base64String}
                  style={{ width: '100%', height: '100%', maxHeight: '400px' }}
                  hideExtraControls={true}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Stats;