import React from "react";
import { ReactMultiEmail } from "react-multi-email";
import "./MultiEmailField.css"; // Import our custom styles
import { useTranslation } from 'react-i18next';

const MultiEmailField = ({ emails, setEmails, hideLabel }) => {
  const { t } = useTranslation();

  return (
    <div className="email-field-container">
      {!hideLabel && (
      <label htmlFor="collaborator-emails" className="field-label">
        {t('multiEmailField.label')}
      </label>
      )}
      <ReactMultiEmail
        id="collaborator-emails"
        emails={emails}
        onChange={(newEmails) => {
          setEmails(newEmails);
        }}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              {email}
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default MultiEmailField;