import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider, Box, Grid, Modal } from "@mui/material";
import {
  TrendingUp as TrendingUpIcon,
  AccessTime as AccessTimeIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
} from "@mui/icons-material";
import { motion } from "framer-motion";

import { GetStats } from "../../services/user.service";
import { theme, modalStyle } from "../../theme";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import CreateProjectFlow from "../../components/CreateProject/CreateProjectFlow";
import ProjectList from "./Components/ProjectList";
import StatCard from "./Components/StatCard";
import DashboardHeader from "./Components/DashboardHeader";

const Dashboard = ({ userData, accessToken, projects, setProjects }) => {
  const { t } = useTranslation();
  const { user, isLoading: authLoading } = useAuth0();
  const navigate = useNavigate();
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [showCalcExplanationPopUp, setShowCalcExplanationPopUp] =
    useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (!authLoading && user && accessToken) {
      const fetchData = async () => {
        try {
          const statsData = await GetStats(accessToken, user.sub.split("|")[1]);
          setStats(statsData.data);
        } catch (error) {
          console.error("Error fetching data:", error);
          setMessageType("error");
          setMessage("Error getting projects and stats");
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [authLoading, user, accessToken]);

  if (isLoading || authLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <LoadingScreen
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          header={t("dashboard.loading")}
          text={t("dashboard.loadingText")}
        />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <DashboardHeader
            user={user}
            userData={userData}
            accessToken={accessToken}
          />
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={4}>
              <StatCard
                title={t("dashboard.imagesProcessed")}
                value={stats?.non_complex_images_processed}
                icon={<TrendingUpIcon color="primary" />}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <StatCard
                title={t("dashboard.timeSaved")}
                value={stats?.time_saved}
                icon={<AccessTimeIcon color="primary" />}
                showPopup={showCalcExplanationPopUp}
                onMouseEnter={() => setShowCalcExplanationPopUp(true)}
                onMouseLeave={() => setShowCalcExplanationPopUp(false)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <StatCard
                title={t("dashboard.projectsInitiated")}
                value={projects ? projects.length : 0}
                icon={<PeopleAltOutlinedIcon color="primary" />}
              />
            </Grid>
          </Grid>
          <ProjectList
            projects={projects}
            navigate={navigate}
            handleCreateProject={() => setOpenCreateProject(true)}
            sortingMethod="Date"
          />
        </motion.div>

        <Modal
          open={openCreateProject}
          onClose={() => setOpenCreateProject(false)}
        >
          <Box sx={modalStyle}>
            <CreateProjectFlow
              setOpenCreateProject={setOpenCreateProject}
              accessToken={accessToken}
              userID={user.sub.split("|")[1]}
              setProjects={setProjects}
            />
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;
