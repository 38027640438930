import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Container, Grid, ThemeProvider, Typography } from '@mui/material';
import { Link } from '../../theme';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { theme, StyledLogo } from "../../theme";
import Logo from "../../assets/images/Logos/Pytri_Logo.png";

const Footer = () => {
  const { isAuthenticated } = useAuth0();
  const { t } = useTranslation();

  const links = isAuthenticated
    ? [
      { text: t("sidebar.dashboard"), href: "/dashboard" },
      { text: t("navigation.logout"), href: "/logout" },
    ]
    : [
      { text: t("navigation.home"), href: "/" },
      { text: t("navigation.products"), href: "/products" },
      { text: t("navigation.pricing"), href: "/pricing" },
      { text: t("navigation.contact"), href: "/contact" },
      { text: t("navigation.login"), href: "/login" },
    ];

  return (
    <ThemeProvider theme={theme}>
      <Box component="footer" sx={{ bgcolor: 'primary.main', color: 'white', py: 4 }}>
        <Container maxWidth="lg">
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12} md={5}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
                {t("footer.navigation")}
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px 24px' }}>
                {links.map((link, index) => (
                  <Link
                    key={index}
                    component={RouterLink}
                    to={link.href}
                    sx={{
                      color: 'white',
                      '&:hover': { color: 'secondary.main' },
                      fontSize: '0.875rem',
                    }}
                  >
                    {link.text}
                  </Link>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
                {t("footer.contact")}
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px 24px' }}>
                <Link
                  href="https://pytri.freshdesk.com/support/tickets/new"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: 'white', fontSize: '0.875rem', '&:hover': { color: 'secondary.main' } }}
                >
                  {t("footer.support")}
                </Link>
                <Link href="mailto:info@Pytri.ca" sx={{ color: 'white', fontSize: '0.875rem', '&:hover': { color: 'secondary.main' } }}>
                  info@Pytri.ca
                </Link>
                <Link
                  href="https://ca.linkedin.com/company/pytri"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: 'white',
                    fontSize: '0.875rem',
                    '&:hover': { color: 'secondary.main' },
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  LinkedIn
                </Link>
                <Link href="tel:+19704454567" sx={{ color: 'white', fontSize: '0.875rem', '&:hover': { color: 'secondary.main' } }}>
                  +1 970-445-4567
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px 8px' }}>
              <StyledLogo
                src={Logo}
                alt="Pytri Logo"
                sx={{ width: 'auto', height: '60px' }} // Adjust size as needed
              />
              <Typography sx={{ color: 'white', mt: 1, fontSize: '1.50rem', fontWeight: 'bold' }}>
                Pytri
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;