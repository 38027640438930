import React, { useState, useEffect } from 'react';
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Paper,
    styled
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Alert from '@mui/material/Alert';
import { BloodSmearRBCTypes } from '../../../../../utils/enums';
import { theme, ColorButton, InverseButton } from '../../../../../theme';
import { ThemeProvider } from '@mui/system';
import DeleteModal from '../../DeleteModal';
import { DeleteProjectTrial, DeleteImage } from '../../../../../services/project.service';


// Styled components for consistent styling
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        fontSize: '0.875rem',
    },
});

const TrialTable = ({
    projectTrials,
    setProjectTrials,
    projectImages,
    setProjectImages,
    editMode,
    setEditMode,
    trialBeingEdited,
    SetEditing,
    SaveChangesToTrial,
    cancelEdit,
    createCSV,
    OpenImage,
    accessToken,
}) => {

    const { t } = useTranslation();
    const cellTypes = Object.values(BloodSmearRBCTypes);
    const [openDeleteTrialConfirmationDialog, setOpenDeleteTrialConfirmationDialog] = useState(false);
    const [openDeleteImageConfirmationDialog, setOpenDeleteImageConfirmationDialog] = useState(false);
    const [trialDeleteID, setTrialDeleteID] = useState(null);
    const [imageDeleteID, setImageDeleteID] = useState(null);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    const [startDates, setStartDates] = useState([]);
    const [endDates, setEndDates] = useState([]);

    const confirmDeleteTrial = () => {
        let formdata = new FormData();
        formdata.append('trial_id', trialDeleteID);
        DeleteProjectTrial(accessToken, formdata)
            .then((response) => {
                if (response.error) {
                    throw new Error(response.error);
                }
                setProjectTrials(projectTrials.filter(trial => trial._id !== trialDeleteID));
                setTrialDeleteID(null);
                setSeverity('success');
                setMessage(t('projectBody.success.trialDeleted'));
            })
            .catch((error) => {
                setSeverity('error');
                setMessage(t('projectBody.errors.deleteTrialError', { error: error.message }));
            })
            .finally(() => {
                setTimeout(() => {
                    setSeverity('');
                    setMessage('');
                }, 3000);
                setEditMode(false);
                setOpenDeleteTrialConfirmationDialog(false);
            });
    };

    const confirmDeleteImage = () => {
        let formdata = new FormData();
        formdata.append('image_id', imageDeleteID);
        DeleteImage(accessToken, formdata)
            .then((response) => {
                if (response.error) {
                    throw new Error(response.error);
                }
                setProjectImages(projectImages.filter(image => image.image_id !== imageDeleteID));
                setSeverity('success');
                setMessage(t('projectBody.success.imageDeleted'));
            })
            .catch((error) => {
                setSeverity('error');
                setMessage(t('projectBody.errors.deleteImageError', { error: error.message }));
            })
            .finally(() => {
                setTimeout(() => {
                    setSeverity('');
                    setMessage('');
                }, 3000);
                setEditMode(false);
                setOpenDeleteImageConfirmationDialog(false);
                window.location.reload();
            });
    };

    useEffect(() => {
        if (!projectTrials) return;
        setStartDates(projectTrials.map((trial) => new Date(trial.start_date)));
        setEndDates(projectTrials.map((trial) => trial.end_date !== '' ? new Date(trial.end_date) : null));
    }, [projectTrials, setStartDates, setEndDates]);

    const splitFilename = (filename) => {
        const lastDotIndex = filename.lastIndexOf('.');
        const name = filename.slice(0, lastDotIndex);
        const extension = filename.slice(lastDotIndex);
        return { name, extension };
    };

    const handleFilenameChange = (image, newName) => {
        const { extension } = splitFilename(image.filename);
        image.filename = `${newName}${extension}`;
    };

    const Row = React.memo(({ trial, trialIndex }) => {
        const [open, setOpen] = useState(false);
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(5);
        const isEditing = editMode && trialBeingEdited === trial._id;

        useEffect(() => {
            if (isEditing) {
                setOpen(true);
            }
        }, [isEditing]);

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
        };

        const toggleOpen = () => setOpen(!open);

        return (
            <React.Fragment>
                <TableRow>
                    <TableCell>
                        <StyledIconButton
                            aria-label="expand row"
                            size="small"
                            onClick={toggleOpen}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </StyledIconButton>
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        onClick={toggleOpen}
                        style={{ cursor: 'pointer', color: '#20B3CE' }}
                    >
                        {isEditing ? (
                            <StyledTextField
                                defaultValue={trial.name}
                                onChange={(e) => trial.name = e.target.value}
                                onClick={(e) => e.stopPropagation()}
                            />
                        ) : (
                            trial.name
                        )}
                    </TableCell>
                    <TableCell>
                        {isEditing ? (
                            <DatePicker
                                value={startDates[trialIndex]}
                                onChange={(date) => setStartDates(startDates.map((startDate, i) => i === trialIndex ? date : startDate))}
                                renderInput={(params) => <StyledTextField {...params} />}
                            />
                        ) : (
                            trial.start_date !== '' ? new Date(trial.start_date).toLocaleDateString() : t('common.notSet')
                        )}
                    </TableCell>
                    <TableCell>
                        {isEditing ? (
                            <DatePicker
                                value={endDates[trialIndex]}
                                onChange={(date) => setEndDates(endDates.map((endDate, i) => i === trialIndex ? date : endDate))}
                                renderInput={(params) => <StyledTextField {...params} />}
                            />
                        ) : (
                            trial.end_date !== '' ? new Date(trial.end_date).toLocaleDateString() : t('common.notSet')
                        )}
                    </TableCell>
                    <TableCell>
                        {isEditing ? (
                            <StyledTextField
                                defaultValue={trial.notes}
                                onChange={(e) => trial.notes = e.target.value}
                            />
                        ) : (
                            trial.notes ? trial.notes : t('common.notSet')
                        )}
                    </TableCell>
                    <TableCell>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', justifyContent: 'flex-end', alignItems: 'center', marginRight: '10px' }}>
                            {isEditing ? (
                                <>
                                    <ColorButton onClick={SaveChangesToTrial}>{t('trialTable.save')}</ColorButton>
                                    <InverseButton onClick={cancelEdit}>{t('trialTable.cancel')}</InverseButton>
                                    <DeleteForeverIcon
                                        fontSize='large'
                                        style={{ cursor: 'pointer', color: '#d11a2a' }}
                                        onClick={() => {
                                            setOpenDeleteTrialConfirmationDialog(true);
                                            setTrialDeleteID(trial._id);
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <ColorButton onClick={() => createCSV(trialIndex, projectTrials, projectImages)}>{t('trialTable.export')}</ColorButton>
                                    <EditSharpIcon onClick={() => { SetEditing(trial._id) }} />
                                </>
                            )}
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {projectImages[trialIndex] !== undefined && (
                                projectImages[trialIndex].length === 0 ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                        <p>{t('trialTable.noImagesFound')}</p>
                                    </Box>
                                ) : (
                                    <Box sx={{ margin: 1 }}>
                                        <Table size="small" aria-label="images">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>{t('trialTable.imageName')}</StyledTableCell>
                                                    <StyledTableCell>{t('trialTable.assayType')}</StyledTableCell>
                                                    {projectImages[trialIndex].some((image) => image.assay_type !== 'Gel Electrophoresis') && (
                                                        <StyledTableCell>{t('trialTable.colonyCount')}</StyledTableCell>
                                                    )}
                                                    {projectImages[trialIndex].some((image) => image.assay_type === 'GFP/OFP') && (
                                                        <>
                                                            <StyledTableCell>{t('trialTable.gfpCount')}</StyledTableCell>
                                                            <StyledTableCell>{t('trialTable.ofpCount')}</StyledTableCell>
                                                        </>
                                                    )}
                                                    {projectImages[trialIndex].some((image) => image.assay_type === 'Membrane') && (
                                                        <>
                                                            <StyledTableCell>{t('trialTable.eColiCount')}</StyledTableCell>
                                                            <StyledTableCell>{t('trialTable.otherColiform')}</StyledTableCell>
                                                        </>
                                                    )}
                                                    {cellTypes.map((cellType) => (
                                                        projectImages[trialIndex].some((image) => image[cellType]) && (
                                                            <StyledTableCell key={cellType}>{cellType}</StyledTableCell>
                                                        )
                                                    ))}
                                                    {projectImages[trialIndex].some((image) => image.assay_type !== 'Gel Electrophoresis') && (
                                                        <>
                                                            <StyledTableCell>{t('trialTable.volumePlated')}</StyledTableCell>
                                                            <StyledTableCell>{t('trialTable.dilutionFactor')}</StyledTableCell>
                                                        </>
                                                    )}
                                                    <StyledTableCell>{t('trialTable.notes')}</StyledTableCell>
                                                    <StyledTableCell>{isEditing ? t('trialTable.delete') : t('trialTable.timestamp')}</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {projectImages[trialIndex].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((image, imageIndex) => (
                                                    <TableRow key={imageIndex}>
                                                        <TableCell component="th" scope="row" onClick={() => !isEditing && OpenImage(image)} style={{ cursor: isEditing ? 'default' : 'pointer', color: '#20B3CE' }}>
                                                            {isEditing ? (
                                                                <StyledTextField
                                                                    defaultValue={splitFilename(image.filename).name}
                                                                    onChange={(e) => handleFilenameChange(image, e.target.value)}
                                                                />
                                                            ) : (
                                                                image.filename
                                                            )}
                                                        </TableCell>
                                                        <TableCell>{image.assay_type}</TableCell>
                                                        {projectImages[trialIndex].some((image) => image.assay_type !== 'Gel Electrophoresis') && (
                                                            <TableCell>
                                                                {isEditing ? (
                                                                    <StyledTextField
                                                                        defaultValue={image.total_detections}
                                                                        onChange={(e) => image.total_detections = e.target.value}
                                                                    />
                                                                ) : (
                                                                    image.total_detections
                                                                )}
                                                            </TableCell>)}
                                                        {projectImages[trialIndex].some((img) => img.assay_type === 'GFP/OFP') && (
                                                            <>
                                                                <TableCell>
                                                                    {isEditing ? (
                                                                        <StyledTextField
                                                                            defaultValue={image.gfp_counts}
                                                                            onChange={(e) => image.gfp_counts = e.target.value}
                                                                        />
                                                                    ) : (
                                                                        image.gfp_counts ? image.gfp_counts : t('common.notSet')
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {isEditing ? (
                                                                        <StyledTextField
                                                                            defaultValue={image.ofp_counts}
                                                                            onChange={(e) => image.ofp_counts = e.target.value}
                                                                        />
                                                                    ) : (
                                                                        image.ofp_counts ? image.ofp_counts : t('common.notSet')
                                                                    )}
                                                                </TableCell>
                                                            </>
                                                        )}
                                                        {projectImages[trialIndex].some((img) => img.assay_type === 'Membrane') && (
                                                            <>
                                                                <TableCell>
                                                                    {isEditing ? (
                                                                        <StyledTextField
                                                                            defaultValue={image.eColi_counts}
                                                                            onChange={(e) => image.eColi_counts = e.target.value}
                                                                        />
                                                                    ) : (
                                                                        image.eColi_counts ? image.eColi_counts : t('common.notSet')
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {isEditing ? (
                                                                        <StyledTextField
                                                                            defaultValue={image.other_coliform_counts}
                                                                            onChange={(e) => image.other_coliform_counts = e.target.value}
                                                                        />
                                                                    ) : (
                                                                        image.other_coliform_counts ? image.other_coliform_counts : t('common.notSet')
                                                                    )}
                                                                </TableCell>
                                                            </>
                                                        )}
                                                        {cellTypes.map((cellType) => (
                                                            projectImages[trialIndex].some((img) => img[cellType]) && (
                                                                <TableCell key={cellType}>
                                                                    {isEditing ? (
                                                                        <StyledTextField
                                                                            defaultValue={image[cellType]}
                                                                            onChange={(e) => image[cellType] = e.target.value}
                                                                        />
                                                                    ) : (
                                                                        image[cellType] ? image[cellType] : 0
                                                                    )}
                                                                </TableCell>
                                                            )
                                                        ))}
                                                        {projectImages[trialIndex].some((image) => image.assay_type !== 'Gel Electrophoresis') && (
                                                            <>
                                                                <TableCell>
                                                                    {isEditing ? (
                                                                        <StyledTextField
                                                                            defaultValue={image.volume_plated}
                                                                            onChange={(e) => image.volume_plated = e.target.value}
                                                                        />
                                                                    ) : (
                                                                        `${image.volume_plated ? image.volume_plated : t('common.notSet')} ${image.volume_units ? image.volume_units : ''}`
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {isEditing ? (
                                                                        <StyledTextField
                                                                            defaultValue={image.dilution_factor}
                                                                            onChange={(e) => image.dilution_factor = e.target.value}
                                                                        />
                                                                    ) : (
                                                                        image.dilution_factor ? image.dilution_factor : t('common.notSet')
                                                                    )}
                                                                </TableCell>
                                                            </>
                                                        )}
                                                        <TableCell>
                                                            {isEditing ? (
                                                                <StyledTextField
                                                                    defaultValue={image.notes}
                                                                    onChange={(e) => image.notes = e.target.value}
                                                                />
                                                            ) : (
                                                                image.notes ? image.notes : t('common.notSet')
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {isEditing ? (
                                                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', justifyContent: 'flex-end', alignItems: 'center', color: '#d11a2a' }}>
                                                                    <DeleteForeverIcon
                                                                        fontSize='large'
                                                                        style={{ cursor: 'pointer', color: '#d11a2a' }}
                                                                        onClick={() => {
                                                                            setOpenDeleteImageConfirmationDialog(true);
                                                                            setImageDeleteID(image._id);
                                                                        }}
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                image.timestamp ? new Date(image.timestamp).toLocaleString() : t('common.notSet')
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={projectImages[trialIndex].length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            className="pagination"
                                        />
                                    </Box>
                                )
                            )}

                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    });

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TableContainer component={Paper} sx={{ backgroundColor: 'white', marginTop: '20px', borderRadius: '10px' }}>
                    <Table aria-label="collapsible table" size="small" sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell />
                                <StyledTableCell>{t('trialTable.trial')}</StyledTableCell>
                                <StyledTableCell>{t('trialTable.startDate')}</StyledTableCell>
                                <StyledTableCell>{t('trialTable.endDate')}</StyledTableCell>
                                <StyledTableCell>{t('trialTable.notes')}</StyledTableCell>
                                <StyledTableCell>
                                    {severity && message && (<Alert severity={severity}>{message}</Alert>)}
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectTrials && projectTrials.map((trial, index) => (
                                <Row key={trial._id} trial={trial} trialIndex={index} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </LocalizationProvider>

            <DeleteModal
                modalTitle={t('deleteModal.trial.title')}
                openDeleteConfirmationDialog={openDeleteTrialConfirmationDialog}
                setOpenDeleteConfirmationDialog={setOpenDeleteTrialConfirmationDialog}
                deleteMessage={t('deleteModal.trial.confirmationMessage', { trialName: projectTrials ? projectTrials.find(trial => trial._id === trialDeleteID)?.name : '' })}
                handleConfirm={confirmDeleteTrial}
            />

            <DeleteModal
                modalTitle={t('deleteModal.image.title')}
                openDeleteConfirmationDialog={openDeleteImageConfirmationDialog}
                setOpenDeleteConfirmationDialog={setOpenDeleteImageConfirmationDialog}
                deleteMessage={t('deleteModal.image.confirmationMessage')}
                typeToConfirmMessage={t('deleteModal.image.typeToConfirm')}
                handleConfirm={confirmDeleteImage}
            />
        </ThemeProvider>
    );
};

export default TrialTable;