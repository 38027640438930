import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add as AddIcon } from '@mui/icons-material';
import { ColorButton } from '../../../theme';
import { ThemeProvider } from '@mui/system';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { theme } from '../../../theme';
import Modal from '@mui/material/Modal';
import ImageUploadModal from '../../../components/ImageUploadModal/ImageUploadModal';
import CloseConfirmationModal from '../../../components/Modals/CloseConfirmationModal';

const DashboardHeader = ({ userData, user, accessToken }) => {

  // state
  const [openImageUploadModal, setOpenImageUploadModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const { t } = useTranslation();
  const handleUploadImages = () => {
    setOpenImageUploadModal(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4
        }}
      >
        <Box>
          <Typography variant="h4" component="h1" gutterBottom>
            {t("dashboard.pageTitle")}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {t("dashboard.welcome", { name: userData?.name || user?.name })}
          </Typography>
        </Box>
        <ColorButton
          startIcon={<AddIcon />}
          onClick={handleUploadImages}
        >
          {t("dashboard.uploadImages")}
        </ColorButton>
      </Box>

      <CloseConfirmationModal
        openConfirmationModal={openConfirmationModal}
        setOpenConfirmationModal={setOpenConfirmationModal}
        setOpenImageUploadModal={setOpenImageUploadModal}
      />
      <Modal
        open={openImageUploadModal}
        onClose={() => setOpenConfirmationModal(true)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <ImageUploadModal
            setOpenConfirmationModal={setOpenConfirmationModal}
            userID={userData._id}
            nameOfUser={user.name}
            accessToken={accessToken}
          />
        </Box>
      </Modal>
    </ThemeProvider>
  )
}

export default DashboardHeader;