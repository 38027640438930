import React, { useState, useEffect } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert,
    CircularProgress,
    Modal,
    Box,
    Typography,
    Stack,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { ColorButton, InverseButton, theme, modalStyle } from '../../../../theme';
import { GetProjects } from '../../../../services/project.service';
import CreateProjectFlow from '../../../CreateProject/CreateProjectFlow';
import CreateTrialFlow from '../../../CreateTrial/CreateTrialFlow';

const SelectProject = ({
    trialID,
    setTrialID,
    accessToken,
    userID,
    IDofSetProject,
    saveImagesToProject
}) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const [projects, setProjects] = useState([]);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [trials, setTrials] = useState([]);
    const [projectID, setProjectID] = useState(null);
    const [openCreateProject, setOpenCreateProject] = useState(false);
    const [openCreateTrialFlow, setOpenCreateTrialFlow] = useState(false);

    const showPickProjectPrompt = () => {
        setSeverity('error');
        setMessage(t('selectImages.selectProject.errors.selectProjectFirst'));
        setTimeout(() => {
            setSeverity('');
            setMessage('');
        }, 3000);
    };

    const onContinue = () => {
        setLoading(true);
        if (projectID === null || projectID === '') {
            setLoading(false);
            setSeverity('error');
            setMessage(t('selectImages.selectProject.errors.selectProject'));
            setTimeout(() => {
                setSeverity('');
                setMessage('');
            }, 3000);
            return;
        }
        if (trialID === null || trialID === '') {
            setLoading(false);
            setSeverity('error');
            setMessage(t('selectImages.selectProject.errors.selectTrial'));
            setTimeout(() => {
                setSeverity('');
                setMessage('');
            }, 3000);
            return;
        }

        saveImagesToProject(projectID, trialID);
        setLoading(false);
    };

    useEffect(() => {
        if (IDofSetProject) {
            setProjectID(IDofSetProject);
        }
    }, [IDofSetProject]);

    useEffect(() => {
        updateTrials();
    }, [projectID, projects, trials]);

    useEffect(() => {
        if (!accessToken || !userID) return;
        GetProjects(accessToken, userID).then((response) => {
            setProjects(response.data);
        });
    }, [accessToken, userID]);

    const handleProjectDropdownChange = (event) => {
        setProjectID(event.target.value);
        setTrialID(null);
    };

    const handleTrialDropdownChange = (event) => {
        setTrialID(event.target.value);
    };

    const updateTrials = async (newtrial) => {
        if (projectID === null) return;
        const selectedProject = projects.find(project => project._id === projectID);

        if (newtrial) {
            await GetProjects(accessToken, userID).then((response) => {
                const tempProjectID = projectID;
                setProjects(response.data);
                setProjectID(tempProjectID);
                setTrials(response.data.find(project => project._id === tempProjectID).project_trials);
                setTrialID(newtrial._id);
            });
        } else {
            if (selectedProject?.project_trials) {
                setTrials(selectedProject.project_trials);
            } else {
                setTrials([]);
            }
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    maxWidth: isMobile ? '100%' : '600px',
                    margin: '0 auto',
                    padding: isMobile ? theme.spacing(2) : theme.spacing(3),
                    boxSizing: 'border-box',
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        color: 'text.primary',
                        marginBottom: isMobile ? theme.spacing(2) : theme.spacing(4),
                        textAlign: 'center',
                        fontSize: isMobile ? '20px' : '25px',
                        wordBreak: 'break-word'
                    }}
                >
                    {t('selectImages.selectProject.title')}
                </Typography>

                <Stack
                    spacing={isMobile ? 2 : 3}
                    width="100%"
                >
                    <FormControl
                        fullWidth
                        sx={{
                            backgroundColor: 'background.paper',
                            minHeight: isMobile ? '45px' : '56px',
                        }}
                    >
                        <InputLabel
                            required
                            sx={{
                                color: 'text.primary',
                                '&.Mui-focused': {
                                    color: 'primary.main'
                                },
                                fontSize: isMobile ? '14px' : '16px'
                            }}
                        >
                            {t('selectImages.selectProject.project')}
                        </InputLabel>
                        <Select
                            value={projectID || ''}
                            label={t('selectImages.selectProject.project')}
                            onChange={handleProjectDropdownChange}
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'primary.light'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'primary.main'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'primary.main'
                                },
                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: isMobile ? '40vh' : '50vh'
                                    }
                                }
                            }}
                        >
                            <MenuItem>
                                <InverseButton
                                    onClick={() => setOpenCreateProject(true)}
                                    sx={{
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                        padding: isMobile ? '4px 8px' : theme.spacing(1),
                                        fontSize: isMobile ? '14px' : '16px',
                                        '&:hover': {
                                            backgroundColor: 'background.secondary'
                                        }
                                    }}
                                >
                                    {t('selectImages.selectProject.addProject')}
                                </InverseButton>
                            </MenuItem>
                            {projects?.map((project) => (
                                <MenuItem
                                    value={project._id}
                                    key={project._id}
                                    sx={{
                                        padding: isMobile ? '8px 14px' : theme.spacing(1.5),
                                        fontSize: isMobile ? '14px' : '16px',
                                        '&:hover': {
                                            backgroundColor: 'background.secondary'
                                        }
                                    }}
                                >
                                    {project.project_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl
                        fullWidth
                        disabled={!projectID}
                        onMouseDown={projectID ? null : showPickProjectPrompt}
                        sx={{
                            backgroundColor: 'background.paper',
                            opacity: projectID ? 1 : 0.7,
                        }}
                    >
                        <InputLabel
                            required
                            sx={{
                                color: 'text.primary',
                                '&.Mui-focused': {
                                    color: 'primary.main'
                                },
                                fontSize: isMobile ? '14px' : '16px'
                            }}
                        >
                            {t('selectImages.selectProject.trial')}
                        </InputLabel>
                        <Select
                            value={trialID || ''}
                            label={t('selectImages.selectProject.trial')}
                            onChange={handleTrialDropdownChange}
                            sx={{
                                fontSize: isMobile ? '14px' : '16px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'primary.light'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'primary.main'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'primary.main'
                                },
                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: isMobile ? '40vh' : '50vh'
                                    }
                                }
                            }}
                        >
                            {projectID && (
                                <MenuItem>
                                    <InverseButton
                                        onClick={() => setOpenCreateTrialFlow(true)}
                                        sx={{
                                            width: '100%',
                                            justifyContent: 'flex-start',
                                            padding: isMobile ? '4px 8px' : theme.spacing(1),
                                            fontSize: isMobile ? '14px' : '16px',
                                            '&:hover': {
                                                backgroundColor: 'background.secondary'
                                            }
                                        }}
                                    >
                                        {t('selectImages.selectProject.addTrial')}
                                    </InverseButton>
                                </MenuItem>
                            )}
                            {trials?.map((trial) => (
                                <MenuItem
                                    value={trial._id}
                                    key={trial._id}
                                    sx={{
                                        padding: isMobile ? '8px 14px' : theme.spacing(1.5),
                                        fontSize: isMobile ? '14px' : '16px',
                                        '&:hover': {
                                            backgroundColor: 'background.secondary'
                                        }
                                    }}
                                >
                                    {trial.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: isMobile ? theme.spacing(2) : theme.spacing(3)
                    }}>
                        {loading ? (
                            <CircularProgress
                                size={isMobile ? 30 : 40}
                                sx={{
                                    color: 'primary.main',
                                    margin: isMobile ? theme.spacing(1) : theme.spacing(2)
                                }}
                            />
                        ) : (
                            <Stack spacing={2} width="100%">
                                <ColorButton
                                    onClick={onContinue}
                                    fullWidth
                                    sx={{
                                        py: isMobile ? theme.spacing(1) : theme.spacing(1.5),
                                        fontSize: isMobile ? '14px' : '16px',
                                        minHeight: isMobile ? '40px' : '48px'
                                    }}
                                >
                                    {t('selectImages.selectProject.save')}
                                </ColorButton>

                                {(severity === 'error' || severity === 'success') && (
                                    <Alert
                                        severity={severity}
                                        sx={{
                                            '& .MuiAlert-message': {
                                                fontSize: isMobile ? '12px' : '14px'
                                            },
                                            py: isMobile ? 0.5 : 1
                                        }}
                                    >
                                        {message}
                                    </Alert>
                                )}
                            </Stack>
                        )}
                    </Box>
                </Stack>

                <Modal
                    open={openCreateProject}
                    onClose={() => setOpenCreateProject(false)}
                    sx={{
                        '& .MuiBackdrop-root': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)'
                        }
                    }}
                >
                    <Box sx={{
                        ...modalStyle,
                        width: isMobile ? '90%' : isTablet ? '80%' : '60%',
                        maxHeight: isMobile ? '90vh' : '95vh',
                        padding: isMobile ? theme.spacing(1.5) : theme.spacing(2)
                    }}>
                        <CreateProjectFlow
                            setOpenCreateProject={setOpenCreateProject}
                            accessToken={accessToken}
                            userID={userID}
                            setProjects={setProjects}
                        />
                    </Box>
                </Modal>

                <Modal
                    open={openCreateTrialFlow}
                    onClose={() => setOpenCreateTrialFlow(false)}
                    sx={{
                        '& .MuiBackdrop-root': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)'
                        }
                    }}
                >
                    <Box sx={{
                        ...modalStyle,
                        width: isMobile ? '90%' : isTablet ? '80%' : '60%',
                        maxHeight: isMobile ? '90vh' : '95vh',
                        padding: isMobile ? theme.spacing(1.5) : theme.spacing(2)
                    }}>
                        <CreateTrialFlow
                            setOpenCreateTrialFlow={setOpenCreateTrialFlow}
                            openCreateTrialFlow={openCreateTrialFlow}
                            selectedProjectID={projectID}
                            accessToken={accessToken}
                            userID={userID}
                            onCreateTrial={updateTrials}
                        />
                    </Box>
                </Modal>
            </Box>
        </ThemeProvider>
    );
};

export default SelectProject;